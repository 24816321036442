import React, { useEffect, useState } from 'react';
import SfcFormOne from './SfcFormOne';
import SfcFormTwo from './SfcFormTwo';
import SfcFormThree from './SfcFormThree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSfcFareAndAllowance,
  getFareAndAllowanceForDistance,
  getSfcAllowance,
  updateAllowance,
} from '../../redux/slice/sfcFareAndAllowance';
import {
  clearApiStatus,
  clearState,
  postSfcSaveTravelExpence,
  updateSfcTravelExpense,
} from '../../redux/slice/sfcSaveTravelExpence';
import {
  getSfcFromCities,
  getSfcToCities,
} from '../../redux/slice/sfcFormFilters';
import { sfcUserDetails } from '../../redux/slice/sfcUserBasicInfo';
import {
  getSfcTravelExpenseFormInitialState,
  upadateExpenseIdCreationFormData,
} from '../../redux/slice/sfcTravelExpenseForm';
import ErrorsPopup from './ErrorsPopup';
import {
  faAngleLeft,
  faCircleMinus,
  faCirclePlus,
} from '@fortawesome/free-solid-svg-icons';
import Spinner from '../../components/Spinner';
import { fetchSfcTravelingExpences } from '../../redux/slice/sfcTravelingExpences';

const validateSfcForm = ({
  fromTerritoryId,
  toTerritoryId,
  fromCityId,
  toCityId,
  categoryId,
  transportId,
  effectiveDate,
}) => {
  const errors = {};
  if (!fromTerritoryId) errors.fromTerritoryId = 'Please select';
  if (!toTerritoryId) errors.toTerritoryId = 'Please select';
  if (!fromCityId) errors.fromCityId = 'Please select';
  if (!toCityId) errors.toCityId = 'Please select';
  if (!categoryId) errors.categoryId = 'Please select';
  if (!transportId) errors.transportId = 'Please select';
  if (!effectiveDate) errors.effectiveDate = 'select date';
  return [
    !!fromTerritoryId &&
      !!toTerritoryId &&
      !!fromCityId &&
      !!toCityId &&
      !!categoryId &&
      !!transportId &&
      !!effectiveDate,
    errors,
  ];
  // !!effectiveDate]
};

const sfcResponseProcesser = (data) => ({
  oneWayDistance: data.oneWayDistance,
  twoWayDistance: data.twoWayDistance,
  oneWayFare: data.oneWayFare,
  twoWayFare: data.twoWayFare,
  allowance: data.allowance,
  specification: data.specification,
  total: data.total,
});

const formatDate = (date) => {
  const [d1, d2, d3] = date.split('-');
  return d1.length === 4 ? `${d3}-${d2}-${d1}` : date;
};

const isSameSfcFareAndAllowance = (previous, present) => {
  return (
    previous.fromTerritoryId !== present.fromTerritoryId ||
    previous.toTerritoryId !== present.toTerritoryId ||
    previous.fromCityId !== present.fromCityId ||
    previous.toCityId !== present.toCityId ||
    previous.categoryId !== present.categoryId ||
    previous.transportId !== present.transportId
  );
};

const getInvalidDataMessage = (data, user) => {
  if (user.areaRange === 'T') {
    let errorCount = 0;
    let message = 'Please Enter ';
    if (!data.numberOfDoctors) {
      errorCount++;
      message += 'number of Doctors';
    }
    if (!data.numberOfChemist) {
      errorCount++;
      message += errorCount ? ' and number of chemists' : ' number of chemists';
    }
    if (errorCount) {
      return message;
    }
  }
  if (data.fromCityId === data.toCityId && data.categoryId !== 1) {
    return 'Same from city and to city allowed only for HQ Categery.';
  }
  if (data.categoryId === 1 && data.fromCityId !== data.toCityId) {
    return 'For HQ Category from city and to city should be same.';
  }
  return '';
};

const getFareAndAllowanceForDistanceResponseProcessor = (data, allowance) => {
  return {
    oneWayDistance: data.oneWayDistance,
    twoWayDistance: data.twoWayDistance,
    oneWayFare: data.oneWayFare,
    twoWayFare: data.twoWayFare,
    total: allowance + data.twoWayFare,
  };
};

const FormContainer = ({ onBack }) => {
  const [isOneWayDistanceEdited, setIsOneWayDistanceEdited] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const categories = useSelector((state) => state.sfcFormFilters.categories);
  const {
    isLoading: isSaving,
    isSuccess: isSaveSuccess,
    apiError,
  } = useSelector((state) => state.sfcSaveTravelExpence);
  const dispatch = useDispatch();
  const { isLoading, fareAndAllowance, allowance } = useSelector(
    (state) => state.sfcFareAndAllowance
  );

  const { expenseIdCreationFormData, isEditingMode } = useSelector(
    (state) => state.sfcTravelExpenseForm
  );
  const { areaRange: employeeType, data: userInfo } = useSelector(
    (state) => state.sfcUserBasicInfo
  );

  const [activeForm, setActiveForm] = useState(1);

  const onBackHandler = () => {
    localStorage.removeItem("isEdit")
    if (activeForm === 1) onBack && onBack();
    setActiveForm((prev) => (prev <= 1 ? 1 : prev - 1));
    setIsOneWayDistanceEdited(false);
  };

  const onNextHandler = (isWeb) => {
    if (isOneWayDistanceEdited && userInfo) {
      setIsOneWayDistanceEdited(false);
      dispatch(
        getFareAndAllowanceForDistance({
          employeeId: userInfo.employeeId,
          oneWayDistance: expenseIdCreationFormData.oneWayDistance,
          effectiveDate: expenseIdCreationFormData.effectiveDate,
        })
      );
      dispatch(
        getSfcAllowance({
          employeeId: userInfo.employeeId,
          cityId: expenseIdCreationFormData.toCityId,
          effectiveDate: expenseIdCreationFormData.effectiveDate,
        })
      );
      return;
    }
    const [validate, errors] = validateSfcForm(expenseIdCreationFormData);
    if (!validate) {
      setValidationError(errors);
      return;
    }

    if (activeForm === 1) {
      const message = getInvalidDataMessage(
        expenseIdCreationFormData,
        userInfo
      );
      if (message) {
        setErrorMessage(message);
        return;
      }
    }

    // if (
    //   activeForm === 2 &&
    //   expenseIdCreationFormData.categoryId !== 1 &&
    //   expenseIdCreationFormData.oneWayDistance === 0
    // ) {
    //   setValidationError((prev) => ({
    //     ...prev,
    //     oneWayDistance: 'please enter distance',
    //   }));
    //   return;
    // }
    if (!isWeb)
      setActiveForm(
        (prev) => (prev === 3 ? prev : prev + 1)
        // prev === 3 ? prev : prev === 1 ? (validate ? prev + 1 : prev) : prev + 1
      );

    if (activeForm === 1) {
      if (fareAndAllowance === null && validate && userInfo) {
        dispatch(
          fetchSfcFareAndAllowance({
            ...expenseIdCreationFormData,
            employeeId: userInfo.employeeId,
            effectiveDate: expenseIdCreationFormData.effectiveDate,
            companyId: userInfo?.companyId,
          })
        );
      }
      if (allowance === null && validate && !isEditingMode && userInfo) {
        dispatch(
          getSfcAllowance({
            employeeId: userInfo.employeeId,
            cityId: expenseIdCreationFormData.toCityId,
            effectiveDate: expenseIdCreationFormData.effectiveDate,
          })
        );
      }
      if (
        validate &&
        fareAndAllowance &&
        isSameSfcFareAndAllowance(
          fareAndAllowance,
          expenseIdCreationFormData
        ) &&
        userInfo
      ) {
        dispatch(
          fetchSfcFareAndAllowance({
            ...expenseIdCreationFormData,
            employeeId: userInfo.employeeId,
            effectiveDate: expenseIdCreationFormData.effectiveDate,
            companyId: userInfo?.companyId,
          })
        );
      }
      if (
        validate &&
        allowance &&
        isSameSfcFareAndAllowance(
          fareAndAllowance,
          expenseIdCreationFormData
        ) &&
        userInfo
      ) {
        dispatch(
          getSfcAllowance({
            employeeId: userInfo.employeeId,
            cityId: expenseIdCreationFormData.toCityId,
            effectiveDate: expenseIdCreationFormData.effectiveDate,
          })
        );
      }
      return;
    }
    if ((activeForm === 3 || isWeb) && userInfo) {
      const { employeeId, companyId, stpfcId } = userInfo;
      const formData = {
        ...expenseIdCreationFormData,
        employeeId,
        companyId,
        // companyId,
        stpfcId,
        isFareAuto: 0,
        status: 'D',
        specification: 'spec',
        // remarks: 'remark',
        // companyId: 25,
      };

      isEditingMode
        ? dispatch(updateSfcTravelExpense(formData))
        : dispatch(postSfcSaveTravelExpence(formData));
      return;
    }
  };

  const onChangeHandler = ({ target: { name, value } }) => {
    let inputVal = value;
    if (name !== 'remarks') setActiveForm(1);

    if (name === 'effectiveDate') inputVal = formatDate(value);
    if (name === 'oneWayDistance') setIsOneWayDistanceEdited(true);
    dispatch(upadateExpenseIdCreationFormData({ [name]: inputVal }));

    setValidationError({ ...validationError, [name]: '' });
  };

  const webNextHandler = (num) => {
    if (num < activeForm) {
      setActiveForm(num - 1);
    } else if (activeForm === num) {
      setActiveForm(num - 1);
    } else {
      onNextHandler();
    }
  };

  useEffect(() => {
    if (fareAndAllowance && allowance && categories) {
      const allowanceValue =
        allowance[
          Object.keys(allowance).find((attr) =>
            attr.toLowerCase().startsWith(
              categories
                .find(
                  (cat) =>
                    cat.categoryId ===
                    Number(expenseIdCreationFormData.categoryId)
                )
                ?.categoryName.slice(0, 2)
                .toLowerCase()
            )
          )
        ];
      dispatch(updateAllowance(allowanceValue));

      dispatch(
        upadateExpenseIdCreationFormData({
          ...sfcResponseProcesser(
            getFareAndAllowanceForDistanceResponseProcessor(
              fareAndAllowance,
              allowanceValue
            )
          ),
          allowance: allowanceValue,
        })
      );
    }
  }, [fareAndAllowance, allowance, isLoading]);

  useEffect(() => {
    if (expenseIdCreationFormData.fromTerritoryId) {
      dispatch(getSfcFromCities(expenseIdCreationFormData.fromTerritoryId));
    }
  }, [expenseIdCreationFormData.fromTerritoryId]);

  useEffect(() => {
    if (expenseIdCreationFormData.toTerritoryId && employeeType === 'A') {
      dispatch(getSfcToCities(expenseIdCreationFormData.toTerritoryId));
    }
  }, [expenseIdCreationFormData.toTerritoryId]);

  useEffect(() => {
    dispatch(clearState());
    const isEdit = localStorage.getItem('isEdit')
    const activeForm = localStorage.getItem('active')
      ? Number(localStorage.getItem('active'))
      : null;
    localStorage.removeItem('active');
    if (activeForm && isEdit) {
      setActiveForm(activeForm);
      const incompleteSfcExpense = JSON.parse(localStorage.getItem('formData'));
      localStorage.removeItem('formData');
      dispatch(upadateExpenseIdCreationFormData({ ...incompleteSfcExpense }));
    }
  }, []);

  const onPageReloadHandler = (event) => {
    localStorage.setItem('formData', JSON.stringify(expenseIdCreationFormData));
    localStorage.setItem('active', activeForm);
  };

  useEffect(() => {
    window.removeEventListener('beforeunload', onPageReloadHandler);
    window.addEventListener('beforeunload', onPageReloadHandler);
    return () => {
      window.addEventListener('beforeunload', onPageReloadHandler);
    };
  }, [activeForm,expenseIdCreationFormData]);

  useEffect(() => {
    if (isSaveSuccess) {
      onBack();
      dispatch(fetchSfcTravelingExpences());
      dispatch(sfcUserDetails());
    }
  }, [isSaveSuccess]);

  return (
    <div className="h-lvh lg:pt-[57px] flex flex-col justify-between box-border lg:relative">
      {isSaving ? <Spinner transparent={true} /> : null}
      <ErrorsPopup
        show={!!errorMessage}
        message={errorMessage}
        onClose={() => setErrorMessage('')}
      />
      <ErrorsPopup
        show={!!apiError}
        message={apiError}
        onClose={() => {
          setActiveForm(1);
          dispatch(clearApiStatus());
        }}
      />
      <div className="w-full h-full overflow-y-scroll">
        <div>
          <div className="flex p-4 py-8 lg:pb-2 justify-between lg:justify-start lg:gap-4">
            <button className="" onClick={onBackHandler}>
              <FontAwesomeIcon icon={faAngleLeft} size="lg" color="#A04D95" />
            </button>
            <p className="text-primary font-semibold text-[18px]">Add New</p>
            <div></div>
          </div>
          <div className="lg:hidden">
            <div className="max-w-2xl mx-auto w-[80vw] flex items-center font-semibold">
              <div
                className={`bg-primary border-2 border-primary text-white min-w-[36px] h-[36px] flex items-center justify-center rounded-full`}
              >
                1
              </div>
              <div
                className={`w-32  md:w-80 h-1 ${
                  activeForm > 1 ? 'bg-primary' : 'bg-slt-color'
                }`}
              ></div>
              <div
                className={`${
                  activeForm >= 2 && 'bg-primary'
                } border-2 border-primary text-${
                  activeForm >= 2 ? 'white' : 'black'
                } min-w-[36px] h-[36px] flex items-center justify-center rounded-full`}
              >
                <span>2</span>
              </div>
              <div
                className={`w-32 md:w-80 h-1 ${
                  activeForm > 2 ? 'bg-primary' : 'bg-slt-color'
                }`}
              ></div>
              <div
                className={`${
                  activeForm >= 3 && 'bg-primary'
                } border-2 border-primary text-${
                  activeForm >= 3 ? 'white' : 'black'
                } min-w-[36px] h-[36px] flex items-center justify-center rounded-full`}
              >
                <span>3</span>
              </div>
            </div>
          </div>

          {/* Mobile View Form */}
          <div className="mt-8 lg:mt-0 mx-3 lg:hidden">
            {activeForm === 1 ? (
              <SfcFormOne
                errors={validationError}
                onChangeHandler={onChangeHandler}
                formValues={expenseIdCreationFormData}
              />
            ) : activeForm === 2 ? (
              <SfcFormTwo
                // errors={validationError}
                loading={isLoading}
                onChangeHandler={onChangeHandler}
                formValues={expenseIdCreationFormData}
                // disableOneway={
                //   Number(expenseIdCreationFormData.categoryId) === 1 ||
                //   (Boolean(fareAndAllowance) &&
                //     fareAndAllowance.oneWayDistance !== 0)
                // }
              />
            ) : activeForm === 3 ? (
              <SfcFormThree
                onChangeHandler={onChangeHandler}
                formValues={expenseIdCreationFormData}
              />
            ) : null}
          </div>

          {/* Web View Form */}
          <div className="mt-8 lg:mt-0 mx-3 smAndMd:hidden mb-5">
            {activeForm >= 1 ? (
              <SfcFormOne
                errors={validationError}
                onChangeHandler={onChangeHandler}
                formValues={expenseIdCreationFormData}
              />
            ) : null}
            <div
              className="p-2 py-4 text-primary flex gap-3 items-center pb-3 border-b-[1px] border-line-color cursor-pointer"
              onClick={webNextHandler.bind(this, 2)}
            >
              <FontAwesomeIcon
                icon={activeForm >= 2 ? faCircleMinus : faCirclePlus}
                color={'#A04D95'}
                size={'xl'}
              />
              <p className="font-semibold">Distance & Fare and Allowances</p>
            </div>

            {activeForm >= 2 ? (
              <SfcFormTwo
                // errors={validationError}
                loading={isLoading}
                onChangeHandler={onChangeHandler}
                formValues={expenseIdCreationFormData}
                // disableOneway={
                //   Number(expenseIdCreationFormData.categoryId) === 1 ||
                //   (Boolean(fareAndAllowance) &&
                //     fareAndAllowance.oneWayDistance !== 0)
                // }
              />
            ) : null}
            <div
              className="p-2 py-4 text-primary flex gap-3 items-center pb-3 border-b-[1px] border-line-color cursor-pointer"
              onClick={webNextHandler.bind(this, 3)}
            >
              <FontAwesomeIcon
                icon={activeForm >= 3 ? faCircleMinus : faCirclePlus}
                color={'#A04D95'}
                size={'xl'}
              />
              <p className="font-semibold">Remarks & Specification</p>
            </div>

            {activeForm >= 3 ? (
              <SfcFormThree
                onChangeHandler={onChangeHandler}
                formValues={expenseIdCreationFormData}
              />
            ) : null}
          </div>
        </div>
      </div>

      <div className="">
        <div className="flex justify-end p-5 smAndMd:shadow-top">
          {/* Mobile */}
          <button
            className="lg:hidden w-[171px] bg-primary text-white rounded-full p-3 font-bold"
            onClick={onNextHandler.bind(this, false)}
          >
            {isEditingMode && activeForm === 3
              ? 'Update'
              : activeForm === 3
              ? 'Add'
              : 'Next'}
          </button>
          {/* Desktop */}
          <button
            disabled={activeForm < 2}
            className="smAndMd:hidden w-[171px] bg-primary text-white rounded-full p-3 font-bold disabled:bg-gray-200"
            onClick={onNextHandler.bind(this, true)}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormContainer;
