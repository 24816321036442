export const truncateText = (text, maxLength) => {
    if (!text) return ''; // Handle cases where text is undefined or null
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };


  export function dateFormatYYYYMMDD(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }




  export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
