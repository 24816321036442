import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfileImage } from '../../redux/slice/userCameraProfile';

// images
import uploadBillWhiteIcon from '/assets/images/uploadBillWhiteIcon.png';
import defaultUserIcon from '/assets/images/defaultUserIcon.png';

const Sidebar = ({ isOpen, toggleDrawer, config = [] }) => {
  const drawerRef = useRef(null);
  const dispatch = useDispatch();
  const { data: image } = useSelector((state) => state.userProfileImage);
  const location = useLocation();

  // Close sidebar when clicking outside
  const handleClickOutside = (event) => {
    if (
      isOpen &&
      drawerRef.current &&
      !drawerRef.current.contains(event.target)
    ) {
      toggleDrawer(false);
    }
  };

  const userData = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    dispatch(getUserProfileImage());
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden" // Only shows backdrop on small and medium screens
          onClick={() => toggleDrawer(false)}
        ></div>
      )}

      <div
        ref={drawerRef}
        className={`fixed lg:static top-0 left-0 z-50 h-full transition-transform duration-300 lg:w-1/5 smAndMd:w-3/5 lg:bg-[#123C69] smAndMd:bg-white text-gray-400 overflow-y-auto lg:text-white
          ${isOpen ? 'translate-x-0' : '-translate-x-full'}
          lg:translate-x-0 flex flex-col justify-between`}
      >
        {/* User Information */}
        <div className="lg:pt-20">
          <Link
            to="/user"
            className="block p-4"
            onClick={() => toggleDrawer(false)}
          >
            <div className="flex items-center">
              <img
                src={image?.imageBase64 || defaultUserIcon}
                alt="User"
                className="w-14 h-14 rounded-full"
              />
              <div className="ml-3">
                <p className="text-sm text-gray-400">
                  {userData?.employeeName || 'Unknown'}
                </p>
                <p className="text-sm text-gray-400 lg:text-baseSm">
                  {userData?.companyId || 'Company ID'}
                </p>
                <p className="text-sm text-gray-400 ">
                  {userData?.companyName?.slice(0, 16) || 'Company Name'}
                </p>
              </div>
            </div>
          </Link>
        </div>

        {/* Navigation Links */}
        <nav className="flex-1  smAndMd:p-2">
          <ul>
            {config
              ?.filter(
                (item) => item.path !== '/setting' && item.path !== '/logout'
              )
              ?.map((item) => (
                <li key={item.path}>
                  <Link
                    to={item?.path}
                    onClick={() => toggleDrawer(false)}
                    className={`flex items-center lg:p-2 smAndMd:pt-2 lg:hover:bg-[#184C84] ${
                      location.pathname === item.path
                        ? 'lg:bg-[#184C84]  lg:text-white'
                        : ''
                    }`}
                  >
                    <img
                      src={item?.icon}
                      alt={item.name}
                      className="h-4 w-4 filter-gray lg:filter-white ml-2"
                    />
                    <span className="ml-3 lg:text-baseMd">{item?.name}</span>
                  </Link>
                </li>
              ))}

            <li className="hidden lg:block">
              <Link
                to="/upload-bills"
                onClick={() => toggleDrawer(false)}
                className={`flex items-center p-2 lg:hover:bg-[#184C84] ${
                  location.pathname === '/upload-bills'
                    ? 'lg:bg-[#184C84] lg:text-white'
                    : ''
                }`}
              >
                <img
                  src={uploadBillWhiteIcon}
                  alt="expense-icon"
                  className="h-4 w-4 filter-gray lg:filter-white ml-2"
                />
                <span className="ml-3 lg:text-baseMd ">{'Upload Bills'}</span>
              </Link>
            </li>
          </ul>
        </nav>

        {/* Footer Links */}
        <div className="p-4">
          <ul className="flex justify-between">
            {config
              ?.filter(
                (item) => item.path === '/setting' || item.path === '/logout'
              )
              ?.map((item) => (
                <li key={item?.path}>
                  <Link
                    to={item?.path}
                    onClick={() => toggleDrawer(false)}
                    className={`flex items-center p-2 lg:hover:bg-[#184C84] ${
                      location.pathname === item.path
                        ? 'bg-[#184C84] text-white'
                        : ''
                    }`}
                  >
                    <img
                      src={item?.icon}
                      alt={item.name}
                      className="h-4 w-4 filter-gray lg:filter-white"
                    />
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
