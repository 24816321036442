import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axiosInstance';
import {
  errorResponseParser,
  successResponseParser,
} from '../../utils/handlers';


export const getUserProfileImage = createAsyncThunk(
  'getUserProfileImage',
  async (_, { rejectWithValue, dispatch }) => {
    const userId = JSON.parse(localStorage.getItem("userData"))?.employeeId
  try {
    const res = await axios.get(`/getEmployeeProfileImage`, {
      params: { employeeId: userId },
    });
    return successResponseParser(res);
  } catch (error) {
    return rejectWithValue(errorResponseParser(error, dispatch));
  }
}
);


export const updateUserProfileImage = createAsyncThunk(
    'updateUserProfileImage',
    async (profileImage, { rejectWithValue, dispatch }) => {
      const userId = JSON.parse(localStorage.getItem("userData"))?.employeeId
    try {
      const res = await axios.patch(`/employeeProfileImage`, {
        employeeId: userId,
        imageBase64: profileImage,
      });
      return successResponseParser(res);
    } catch (error) {
      return rejectWithValue(errorResponseParser(error, dispatch));
    }
  }
);

export const userProfileImage = createSlice({
  name: 'userProfileImage',
  initialState: {
    isLoading: false,
    data: null,
    apiError: '',
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserProfileImage.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateUserProfileImage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isUserUpdate = true;
    });
    builder.addCase(updateUserProfileImage.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
    });

////////////////////////get user profile image////////////////

    builder.addCase(getUserProfileImage.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getUserProfileImage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isUserUpdate = true;
    });
    builder.addCase(getUserProfileImage.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
    });
  },
});

export default userProfileImage.reducer;