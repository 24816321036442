import React from 'react';
import SpinnerIcon from '../../assets/icons/spinner-icon.png';
import './style.css';

const Spinner = ({ text = '', transparent = false }) => {
  return (
    <div
      className={`absolute w-full h-full ${
        transparent ? 'bg-transparent' : 'bg-disable'
      } flex justify-center items-center z-50 top-0 left-0`}
    >
      <div
        className={`flex flex-col gap-5 justify-center items-center h-16 w-16 rounded-md ${
          transparent ? '' : 'bg-white'
        }`}
      >
        <img className="spinner w-[80px]" src={SpinnerIcon} />
        <p className="text-primary ">{text}</p>
      </div>
    </div>
  );
};

export default Spinner;
