import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/modal/Loader';
import {
  getAllUnreportedExpense,
  fetchUnReportedData,
  setSelectedAttachment,
  deleteUnReported,
} from '../../redux/slice/unReportedSlice';


// import SideBar from '../../components/sidebar/SideBar';
import deleteIcon from '/assets/images/deleteIcon.png';
import editIconPrimary from '/assets/images/editIconPrimary.png';
import ConfirmationPopup from '../sfc/ConfirmationPopup';
import addIcon from '/assets/images/addIcon.png';
import leftArrowIcon from '/assets/images/leftArrowIcon.png';
import Footer from '../../components/footer/Footer';
import { getAttachmentDaywise } from '../../redux/slice/myExpenseDayWiseSlice';

const UnReportedExpenses = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allAttachement = useSelector(getAttachmentDaywise);
  const [isDeleteUnreported, setIsDeleteUnreported] = useState(false);

  const { unReportedData,isLoading, apiError } = useSelector(
    getAllUnreportedExpense
  );

  useEffect(() => {
    const data= JSON?.parse(
      localStorage?.getItem('SelectedExpenseData')

    );
    const activityDate = data?.activityDate;
    let activityDateFormat = state ? activityDate : '';
    dispatch(fetchUnReportedData(activityDateFormat));
  }, [dispatch, state]);

  const handleAttachment = async (invoice) => {
    if (state) {
      const selectedExpenseData = localStorage.getItem('SelectedExpenseData');
      if (selectedExpenseData) {
        const getDayWiseData = JSON.parse(selectedExpenseData);
        const { activityDate, expenseDetailId, expenseId } = getDayWiseData;
        const {
          employeeId,
          expenseType,
          amount,
          description,
          unReportExpenseId,
          expenseOrSundry,
        } = invoice;

        const attachmentData = {
          activityDate,
          expenseDetailId,
          expenseId,
          employeeId,
          expenseType,
          fare: amount,
          description,
          unReportExpenseId,
          travelMode: 0,
          expenseOrSundry,
        };
        await dispatch(setSelectedAttachment(attachmentData));
        navigate('/my-expense-details');
      }
    }
  };

  const handlerUnreportedEdit = (data) => {
    localStorage.setItem('selectedUnreported', JSON.stringify(data));
    navigate('/captured-image-details', { state: true });
  };

  const handlerunReportedEditUploadbill = (data) => {
    localStorage.setItem('uploadBill', JSON.stringify(data));
    navigate('/upload-bills', { state: true });
  }

  return (
    <div className="relative lg:pt-12">
      {isLoading && <Loader />}
      {!isLoading && apiError && (
        <h1 className="text-center text-semibold text-red-500">{apiError}</h1>
      )}

      {!isLoading && !apiError && (
        <div className="p-4">
          <div className="flex items-center w-full pb-2">
            {/* <SideBar /> */}
            <div className="lg:hidden">
              <img
                src={leftArrowIcon}
                alt="addIcon"
                height={'13px'}
                width={'10px'}
                onClick={() => navigate(-1)}
              />
            </div>
            <p className="text-baselg  font-bold lg:font-semibold text-center text-primary smAndMd:pl-20">
              Unreported Bills
            </p>
          </div>

          <div className="w-full h-[1px] bg-line-color"></div>
          <div className="lg:hidden">
            <div>
              {unReportedData &&
                unReportedData?.length > 0 &&
                unReportedData?.map((invoice, index) => (
                  <div key={index}>
                    <div
                      key={invoice?.unReportExpenseId}
                      className="rounded-md flex justify-between items-center w-full"
                      onClick={() => handleAttachment(invoice)}
                    >
                      <img
                        src={`data:image/jpg;base64,${invoice?.imageBase64}`}
                        alt="Unrecognized"
                        className="w-[79px] h-[79px] p-2 object-cover rounded-xl"
                      />
                      <div className="w-full p-4">
                        <div className="flex flex-row justify-between items-center">
                          <p className="text-baseXSm text-lightGray">
                            {invoice?.invoiceDate?.slice(0, 10)}
                          </p>
                          <p className="text-primary rounded-lg text-baseSm font-semibold">
                            {invoice?.status}
                          </p>
                        </div>
                        <div className="flex flex-row justify-between">
                          <p className="text-baseMd font-medium text-[#49454F]">
                            {invoice?.invoiceNumber?.length > 10
                              ? `${invoice.invoiceNumber.slice(0, 10)}...`
                              : invoice.invoiceNumber || 'N/A'}
                          </p>
                          <p className="font-semibold text-base text-[#514D4C]">
                            ₹ {invoice?.amount || '0.00'}
                          </p>
                        </div>
                        <div className="flex flex-row justify-between items-center">
                          <p className="text-baseXSm text-lightGray">
                            {invoice.expenseType?.length > 8
                              ? `${invoice?.expenseType.slice(0, 10)}...`
                              : invoice.expenseType || 'N/A'}
                          </p>
                          <div className="flex flex-row justify-between min-w-[15%] items-center">
                            <div onClick={() => handlerUnreportedEdit(invoice)}>
                              <img
                                className="text-baseXSm text-lightGray"
                                src={editIconPrimary}
                                height={12}
                                width={13}
                                alt="edit icon"
                              />
                            </div>

                            <div
                              onClick={() =>
                                setIsDeleteUnreported(invoice.unReportExpenseId)
                              }
                            >
                              <img
                                className="text-baseXSm text-lightGray"
                                src={deleteIcon}
                                height={12}
                                width={13}
                                alt="delete icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full h-[1px] bg-line-color"></div>
                  </div>
                ))}
            </div>
            <div className="w-full  bg-red-600 mb-16"> </div>
          </div>

          {/* New div for lg screen */}
          <div className="hidden lg:block lg:pt-4">
            {unReportedData &&
              unReportedData?.length > 0 &&
              unReportedData?.map((invoice, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center w-full border-[1px] border-line-color rounded-md  lg:px-4 lg:mb-4"
                  onClick={() => handleAttachment(invoice)}

                >
                  <div className="flex-1 order-1">
                    <img
                      src={`data:image/jpg;base64,${invoice?.imageBase64}`}
                      alt="Unrecognized"
                      className="w-[4rem] h-[4rem] p-2 object-cover rounded-xl"
                    />
                  </div>
                  <div className="flex-1 order-2">
                    <p className="text-baseSm text-lightGray">
                      {invoice?.invoiceDate?.slice(0, 10)}
                    </p>
                    <p className="text-primary rounded-lg text-baseSm font-semibold">
                      {invoice?.status}
                    </p>
                  </div>
                  <div className="flex-1 order-3">
                    <p className="text-baseSm font-medium text-[#49454F]">
                      {invoice?.invoiceNumber?.length > 10
                        ? `${invoice.invoiceNumber.slice(0, 10)}...`
                        : invoice.invoiceNumber || 'N/A'}
                    </p>
                  </div>
                  <div className="flex-1 order-5">
                    <p className="font-semibold text-base text-[#514D4C]">
                      ₹ {invoice?.amount || '0.00'}
                    </p>
                  </div>
                  <div className="flex-1 order-4">
                    <p className="text-baseSm text-lightGray">
                      {invoice.expenseType?.length > 8
                        ? `${invoice?.expenseType.slice(0, 10)}...`
                        : invoice.expenseType || 'N/A'}
                    </p>
                  </div>
                  <div className="flex-1 flex justify-around min-w-[15%] items-center order-6">
                    <div onClick={() => handlerunReportedEditUploadbill(invoice)}
                    className='cursor-pointer'  >
                      <img
                        className="text-baseXSm text-lightGray"
                        src={editIconPrimary}
                        height={"20px"}
                        width={"20px"}
                        alt="edit icon"
                      />
                    </div>
                    <div
                    className='cursor-pointer'
                      onClick={() =>
                        setIsDeleteUnreported(invoice.unReportExpenseId)
                      }
                    >
                      <img
                        className="text-baseXSm text-lightGray"
                        src={deleteIcon}
                        height={"20px"}
                        width={"20px"}
                        alt="delete icon"
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {!unReportedData?.length && (
            <div className="w-full flex flex-col items-center justify-center h-[70vh] ">
              <div className="block text-primary text-baseMd p-4">
                 <p className='text-[#514D4C]'>Add attachments here</p>
                </div>
              <button
                className="smAndMd:hidden border-primary border-[1px] p-2 flex items-center justify-between w-[10%] rounded-md"
                onClick={() => navigate('/upload-bills')}
              >
                <div className="flex text-primary font-semibold text-baseMd">
                  Add
                </div>
                <div className="flex">
                  <img
                    src={addIcon}
                    alt="add-icon"
                    width={'20px'}
                    height={'20px'}
                  />
                </div>
              </button>

              <button
                className="lg:hidden w-1/3 border-primary border-[1px] p-3 flex items-center justify-evenly rounded-md"
                onClick={() => navigate('/camera')}
              >
                <div className="flex text-primary font-semibold text-baseMd">
                  Add
                </div>
                <div className="flex">
                  <img
                    src={addIcon}
                    alt="add-icon"
                    width={'20px'}
                    height={'20px'}
                  />
                </div>
              </button>
            </div>
          )}
        </div>
      )}
      {isDeleteUnreported && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 w-full h-full'>
        <ConfirmationPopup
          message={'Are you sure, you want to delete?'}
          show={isDeleteUnreported !== false}
          onDenied={() => setIsDeleteUnreported(false)}
          onProceed={async () => {
            await dispatch(deleteUnReported(isDeleteUnreported));
            setIsDeleteUnreported(false);
            dispatch(fetchUnReportedData());
          }}
        />
         </div>
      )}
      <Footer />
    </div>
  );
};

export default UnReportedExpenses;
