import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../components/Spinner';
import { fetchSfcTravelingExpences } from '../../redux/slice/sfcTravelingExpences';
import { sfcUserDetails } from '../../redux/slice/sfcUserBasicInfo';
import ErrorsPopup from './ErrorsPopup';
import { clearApiStatus } from '../../redux/slice/sfcSaveTravelExpence';

const SfcFormThree = ({
  onChangeHandler,
  formValues,
  // goToHomePage,
  // goToFormOne,
}) => {
  // const dispatch = useDispatch();
  // const {
  //   isLoading: isSaving,
  //   isSuccess: isSaveSuccess,
  //   apiError,
  // } = useSelector((state) => state.sfcSaveTravelExpence);
  // useEffect(() => {
  //   if (isSaveSuccess) {
  //     goToHomePage();
  //     dispatch(fetchSfcTravelingExpences());
  //     dispatch(sfcUserDetails());
  //   }
  // }, [isSaveSuccess]);
  return (
    <>
      <div className="lg:p-2 lg:w-[90%]">
        <div className="text-secondary-400 text-fieldset lg:hidden">
          Remarks
        </div>
        <div className="w-1/1 h-[0.5px] bg-line-color mt-4 lg:mt-0 mb-2 lg:hidden"></div>
        <div className="lg:grid grid-cols-2 gap-6">
          <div>
            <input
              onChange={onChangeHandler}
              name="remarks"
              type="text"
              value={formValues?.remarks}
              className="w-full border border-gray-300 mt-5 focus:outline-none rounded h-10 p-2"
              placeholder="Enter remarks"
            />
          </div>
          {formValues.specification ? (
            <div className="mt-4 lg:mt-0">
              <div className="text-secondary-400 text-fieldset lg:hidden">
                Specification
              </div>
              <div className="w-1/1 h-[0.5px] bg-line-color mt-2 lg:hidden"></div>
              <div className="mt-5 flex gap-1 w-full">
                <input
                  type="text"
                  className="border border-gray-300 focus:outline-none rounded grow bg-disable-200 h-10 outline-none"
                  // disabled={true}
                  value={formValues.specification || ''}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
};
export default SfcFormThree;
