import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { deleteUserProfileImage } from '../../../redux/slice/userProfile';
import { updateUserProfileImage } from '../../../redux/slice/userCameraProfile';

const CameraOptionPopup = ({ onCloseModal, setShowCamera }) => {
  const dispatch = useDispatch();
  const inputRef = useRef();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(updateUserProfileImage(reader.result));
        onCloseModal(true);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="absolute w-[248px] z-[11]  bg-[#F8F8F8] border rounded-[20px] z-10 left-[-74px] lg:left-[-48px] shadow-lg">
      <div className="">
        {/* <input
          type="file"
          id="cameraPhotoInput"
          style={{ display: 'none' }}
          accept="image/*"
          multiple
          onChange={onImageHandler}
          onClick={(e) => e.stopPropagation()}
        /> */}
        <div
          className="text-center py-2 text-xs font-medium text-secondary-400 lg:hidden"
          onClick={(e) => {
            e.stopPropagation(), setShowCamera(true);
          }}
        >
          Camera
        </div>
        <div className="h-[1px] bg-[#949494] lg:hidden"></div>
        <div
          className="text-center py-2 text-xs font-medium text-secondary-400 lg:cursor-pointer"
          onClick={() => {
            inputRef.current.click();
          }}
        >
          <input
            id=""
            type="file"
            className="w-0 h-0"
            // onChange={}
            ref={inputRef}
            onChange={handleFileChange}
          />
          Gallery
        </div>
        <div className="h-[1px] bg-[#949494]"></div>
        <div
          className="text-center py-2 text-xs font-medium text-secondary-400 lg:cursor-pointer"
          onClick={() => {
            onCloseModal(true), dispatch(deleteUserProfileImage());
          }}
        >
          Remove
        </div>
        <div className="h-[1px] bg-[#949494]"></div>
        <div
          className="text-center py-2 text-xs font-medium text-secondary-400 lg:cursor-pointer"
          onClick={() => onCloseModal(true)}
        >
          Cancel
        </div>
      </div>
    </div>
  );
};

export default CameraOptionPopup;
