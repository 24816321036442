import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { truncateText } from '../../utils/utilFun';
//images
import bikeIcon from '/assets/images/bikeIcon.png';
import carIcon from '/assets/images/carIcon.png';
import planeIcon from '/assets/images/flightIcon.png';
import trainIcon from '/assets/images/trainIcon.png';
import BusIcon from '/assets/images/busIcon.png';
import taxiIcon from '/assets/images/taxiIcon.png';
import swapIcon from '/assets/images/swapIcon.png';

const expendetHeaderCSS =
  'flex smAndMd:flex-row  smAndMd:items-center flex-col lg:grow lg:items-center justify-between text-secondary-400 text-xs font-medium';
const expendedSemiHeaderCSS =
  'smAndMd:h-[30px] smAndMd:flex lg:h-[45px] lg:flex justify-center items-center lg:bg-[#EEEEEE] smAndMd:w-full w-full smAndMd:text-left lg:text-center';
const expenedParagraphFirstCSS =
  'smAndMd:grow smAndMd:border-r-2 smAndMd:border-white lg:grow md:border-r-[1.5px] lg:border-[#B3B3B3]';
const expenedParagraphSecondCSS =
  'smAndMd:h-[30px] smAndMd:flex lg:h-[45px] smAndMd:w-full smAndMd:text-left smAndMd:justify-end lg:flex justify-center items-center';
const MyExpenseList = ({ id = '332312', item, isMyExpense = false }) => {
  const [expenseDataShow, setExpenseDataShow] = useState(null);
  const [isEpanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    // expenseDetailId,
    fromCity,
    toCity,
    total,
    status,
    activity,
    activityDate,
    category,
    modeOfTransport,
    distance = 0,
    fare = 0,
    categoryName = 'Meeting',
    isModifyRoute = false,
    isHoliday = false,
  } = item;

  const handlerSetData = (data) => {
    if (data) {
      const { activityDateFormat, expenseId, expenseDetailId } = data;
      localStorage.setItem(
        'SelectedExpenseData',
        JSON.stringify({
          activityDate: activityDateFormat,
          expenseId,
          expenseDetailId,
        })
      );
      // dispatch(setSelectedExpense(data));
      navigate('/my-expense-details');
    }
  };

  const handlerClicked = (data) => {
    isMyExpense && handlerSetData(data);
    if (!isMyExpense) {
      setExpanded(!isEpanded);
      setExpenseDataShow(data);
    }
  };

  const dividerClass = 'divider-y h-[1px] lg:h-[0] w-full bg-line-color';

  const transportIcons = {
    '2 Wheeler': bikeIcon,
    'Train': trainIcon,
    'Bus': BusIcon,
    'Flight': planeIcon,
    'Taxi': taxiIcon,
    'default': carIcon,
  };

  const getTransportTypeIcons = (transport) => {
    const icon = transportIcons[transport] || transportIcons.default;
    return (
      <img src={icon} alt={`${transport} icon`} width="16px" height="16px" />
    );
  };

  return (
    <div
      className={`w-full rounded-md smAndMd:p-4 lg:pt-4 lg:pr-4 lg:pl-2 ${
        isModifyRoute ? 'bg-green-200' : 'bg-white'
      }`}
      key={id}
      onClick={isHoliday ? null : () => handlerClicked(item)}
    >
      <div className={`w-full   ${isEpanded ? 'lg:border-b-none lg:border-t lg:border-l lg:border-r rounded-t-md border-line-color' : 'lg:rounded-md lg:border lg:border-line-color'} lg:p-4 lg:cursor-pointer`}>
        <div className="block lg:hidden">
          <div className="w-full flex justify-between items-center">
            <div className="flex items-center gap-4">
              {getTransportTypeIcons(modeOfTransport)}
              <span className="text-lightGray text-baseXSm font-medium">
                {modeOfTransport}
              </span>
              <span className="text-lightGray text-baseXSm font-medium">
                {category}
              </span>
            </div>
            <p className="text-primary text-baseXSm font-semibold">
              {status || ''}
            </p>
          </div>

          <div className="w-full flex justify-between items-center mt-2">
            <div className="flex items-center gap-4">
              <p
                className="text-base text-primary-black"
                style={{ minWidth: '100px' }}
              >
                {fromCity?.length > 10
                  ? fromCity.slice(0, 10) + '...'
                  : fromCity}
              </p>
              <div className="flex items-center justify-center">
                <img src={swapIcon} alt="swap icon" className="h-5 w-5" />
              </div>
              <p
                className="text-base text-primary-black"
                style={{ minWidth: '100px' }}
              >
                {toCity?.length > 10 ? toCity.slice(0, 10) + '...' : toCity}
              </p>
            </div>
            <p className="text-base font-medium">₹ {total || fare}</p>
          </div>

          <div className="w-full flex justify-between items-center mt-2">
            <p className="text-lightGray text-baseSm font-medium">
              {activity || categoryName}
            </p>
            <p style={{ color: '#2FC23D' }} className="text-baseSm font-medium">
              {activityDate}
            </p>
          </div>
        </div>

        {/* Desktop view */}
        <div className="hidden lg:grid lg:grid-cols-8 lg:items-center lg:gap-2 ">
          <div className="p-1 lg:flex lg:items-center lg:gap-2 lg:col-span-1">
            <p className="flex flex-row items-center gap-2">
              {getTransportTypeIcons(modeOfTransport)}
              <span className="text-lightGray text-baseSm font-medium">
                {modeOfTransport}
              </span>
              <span className="text-lightGray text-[11px] font-medium">
                {category}
              </span>
            </p>
          </div>
          <div className="lg:text-center lg:col-span-1">
            <p className="text-lightGray text-[11px] font-medium">
              {activity || categoryName}
            </p>
          </div>

          <div className="flex flex-wrap items-center lg:justify-center lg:col-span-1">
            <p
              className="text-base text-primary-black truncate"
              style={{ minWidth: '100px' }}
            >
              {truncateText(fromCity, 7)}
            </p>
          </div>

          <div className="flex flex-wrap items-center lg:justify-center lg:col-span-1">
            <img src={swapIcon} alt="swap icon" className="h-5 w-5" />
          </div>

          <div className="flex flex-wrap items-center lg:justify-center lg:col-span-1">
            <p
              className="text-base text-primary-black pl-3 truncate"
              style={{ minWidth: '100px' }}
            >
              {truncateText(toCity, 7)}
            </p>
          </div>

          <div className="lg:text-center lg:col-span-1">
            <p className="text-primary text-[11px] font-semibold">
              {status || 'unsubmitted'}
            </p>
          </div>

          <div className="lg:text-center lg:col-span-1">
            <p className="text-[11px] font-medium" style={{ color: '#2FC23D' }}>
              {activityDate}
            </p>
          </div>

          <div className="lg:text-right lg:col-span-1">
            <p className="text-base font-medium">₹ {total || fare}</p>
          </div>
        </div>
      </div>

      {isEpanded && <div className={dividerClass}></div>}
      {isEpanded && expenseDataShow && (
        <div className="lg:flex lg:flex-row lg:border-b-[1px]  lg:border-l-[1px]  lg:border-r-[1px] lg:border-line-color rounded-b-md   lg:cursor-pointer">
          {[
            { label: 'Category', value: activity || categoryName },
            { label: 'Travel Mode', value: modeOfTransport },
            { label: 'Distance', value: distance },
            { label: 'Fare', value: fare },
          ].map((item, index) => {
            const isBorderNeeded = index < 3 ? "md:border-r-[1.5px] lg:border-[#B3B3B3]" : "";
            return (
              <div key={index} className={expendetHeaderCSS}>
                <div className={expendedSemiHeaderCSS}>
                  <p className={`smAndMd:grow smAndMd:border-r-2 smAndMd:border-white lg:grow ${isBorderNeeded}`}>
                    {item.label}
                  </p>
                </div>
                <div className={expenedParagraphSecondCSS}>{item.value}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MyExpenseList;
