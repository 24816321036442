import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
  faMotorcycle,
  faPenToSquare,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { sfcTravelExpenceDelete } from '../../redux/slice/sfcTravelExpenseDelete';
import { useDispatch } from 'react-redux';
import { upadateExpenseIdCreationFormDataForEdit } from '../../redux/slice/sfcTravelExpenseForm';
import CarIcon from '../../assets/icons/car.png';
import BusIcon from '../../assets/icons/bus.png';
import FlightIcon from '../../assets/icons/flight.png';
import TrainIcon from '../../../public/assets/images/trainIcon.png';
import ErrorsPopup from './ErrorsPopup';
import ConfirmationPopup from './ConfirmationPopup';
import ViceversaArrow from '../../components/ViceversaArrow';

const getTransportTypeIcons = (id) => {
  switch (id) {
    case 1:
      return (
        <FontAwesomeIcon
          icon={faMotorcycle}
          className="text-primary"
          width={'20px'}
          height={'20px'}
        />
      );
    case 2:
      return (
        <img src={TrainIcon} alt="train-icon" width={'20px'} height={'20px'} />
      );
    case 3:
      return (
        <img
          src={BusIcon}
          alt="bus-train-icon"
          width={'20px'}
          height={'20px'}
        />
      );
    case 4:
    case 6:
      return (
        <img src={CarIcon} alt="car-taxi-icon" width={'20px'} height={'20px'} />
      );
    case 5:
      return (
        <img
          src={FlightIcon}
          alt="flight-icon"
          width={'20px'}
          height={'20px'}
        />
      );
    default:
      return null;
  }
};

const CardContainer = ({
  status,
  val,
  onEdit,
  display,
  setDetailedViewId,
  onDelete,
}) => {
  const dispatch = useDispatch();
  const [showRemarks, setShowRemarks] = useState(false);

  return (
    <>
      <ErrorsPopup
        show={showRemarks}
        onClose={() => setShowRemarks(false)}
        message={val?.reason}
      />
      <div
        className="smAndMd:p-4 bg-white overflow-hidden flex flex-col gap-5 smAndMd:min-h-[120px] box-border lg:border-[0.5px] border-disable-200 lg:rounded-lg"
        onClick={() => {
          // setDisplay((prev) => !prev)
          display
            ? setDetailedViewId(null)
            : setDetailedViewId(val.stpfcDetailid);
        }}
      >
        <div className="lg:p-4 flex flex-col lg:grid grid-cols-[20%_40%_20%_14%_auto] gap-1 w-full">
          <div className="flex justify-between">
            <div className="flex gap-4 items-center">
              <div className="text-xs">
                {getTransportTypeIcons(val?.transportId)}
              </div>
              <div className="text-secondary text-[10px] lg:text-[12px]">
                {val.transportName}
              </div>
              <div className="text-secondary text-[10px] lg:text-[12px]">
                {val?.categoryName}
              </div>
            </div>

            <p
              className={`${
                status?.includes('Approved') || status === 'Submitted'
                  ? 'text-primary'
                  : status?.includes('Rejected')
                  ? 'text-red-500'
                  : 'text-draft-bg-color'
              }  py-0.5 rounded font-medium flex items-center gap-2 text-[10px] lg:hidden`}
            >
              <span>
                {/* {status?.includes("Approved")
                  ? 'Approved'
                  : status === 'Draft'
                  ? 'Draft'
                  : status === 'Submitted'
                  ? 'Submitted'
                  : status?.includes("Rejected")
                  ? 'Rejected'
                  : null} */}
                {status}
              </span>
              {status?.includes('Approved') || status?.includes('Rejected') ? (
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="text-primary"
                  // width={'20px'}
                  // height={'20px'}
                  size="lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowRemarks(true);
                  }}
                />
              ) : null}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex smAndMd:w-3/4 gap-4 items-center">
              <p className="smAndMd:w-1/2 text-ellipsis overflow-hidden text-sm font-medium lg:text-[16px] ">
                {val?.fromCityName}
              </p>
              <div>
                <ViceversaArrow color="#79747E" />
              </div>
              <p className="smAndMd:w-1/2 text-ellipsis overflow-hidden text-sm font-medium lg:text-[16px] ">
                {val?.toCityName}
              </p>
            </div>
            <div className="text-left text-sm lg:hidden">
              <span className="">₹ </span>
              {val?.total}
            </div>
          </div>
          <p
            className={`${
              status?.includes('Approved') || status === 'Submitted'
                ? 'text-primary'
                : status?.includes('Rejected')
                ? 'text-red-500'
                : 'text-draft-bg-color'
            }  py-0.5 rounded font-medium flex items-center gap-2 text-[14px] smAndMd:hidden lg:text-[12px]`}
          >
            <span>
              {/* {status?.includes("Approved")
                  ? 'Approved'
                  : status === 'Draft'
                  ? 'Draft'
                  : status === 'Submitted'
                  ? 'Submitted'
                  : status?.includes("Rejected")
                  ? 'Rejected'
                  : null} */}
              {status}
            </span>
            {status?.includes('Approved') || status?.includes('Rejected') ? (
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="text-primary"
                // width={'20px'}
                // height={'20px'}
                size="lg"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowRemarks(true);
                }}
              />
            ) : null}
          </p>
          <div className="text-left text-[18px] smAndMd:hidden lg:text-[16px]">
            <span className="">₹ </span>
            {val?.total}
          </div>
          <div className="smAndMd:hidden">
            {status === 'Draft' || status?.includes('Rejected') ? (
              <div className="flex justify-center gap-3">
                <div>
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      onEdit();
                      dispatch(upadateExpenseIdCreationFormDataForEdit(val));
                      localStorage.setItem("isEdit" , true)
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      className="text-primary"
                    />
                  </button>
                </div>
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    onDelete({
                      stpfcId: val?.stpfcId,
                      stpfcDetailid: val?.stpfcDetailid,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} className="text-primary" />
                </button>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        {display ? (
          <>
            <div className="flex justify-between items-center text-secondary text-[10px] lg:hidden">
              <p>Effective Date</p>
              <p>{val?.effectiveDate}</p>
            </div>
            <div className="w-screen h-[0.5px] bg-line-color mt-4 translate-x-[-16px] lg:hidden"></div>
          </>
        ) : null}
        <div
          className={`${
            display ? 'show' : 'hidden'
          } flex flex-col lg:flex-row gap-5 lg:gap-0`}
        >
          <div className="flex lg:flex-col lg:grow lg:items-center justify-between text-secondary-400 text-xs font-medium ">
            <div className="lg:h-[45px] lg:flex justify-center items-center lg:bg-gray-200 lg:w-full lg:text-center">
              <p className="lg:grow lg:border-r-[0.5px] lg:border-r-disable-200">
                Category
              </p>
            </div>
            <div className="lg:h-[45px] lg:flex justify-center items-center">
              {val?.categoryName}
            </div>
          </div>
          <div className="w-full h-[0.5px] bg-line-color lg:hidden"></div>
          <div className="flex lg:flex-col lg:grow lg:items-center justify-between text-secondary-400 text-xs font-medium">
            <div className="lg:h-[45px] lg:flex justify-center items-center lg:bg-gray-200 lg:w-full lg:text-center">
              <p className="lg:grow lg:border-r-[0.5px] lg:border-r-disable-200">
                One-way Distance
              </p>
            </div>
            <div className="lg:h-[45px] lg:flex justify-center items-center">
              {val?.oneWayDistance} <span>KM</span>
            </div>
          </div>
          <div className="flex lg:flex-col lg:grow lg:items-center justify-between text-secondary-400 text-xs font-medium">
            <div className="lg:h-[45px] lg:flex justify-center items-center lg:bg-gray-200 lg:w-full lg:text-center">
              <p className="lg:grow lg:border-r-[0.5px] lg:border-r-disable-200">
                Two-way Distance
              </p>
            </div>
            <div className="lg:h-[45px] lg:flex justify-center items-center">
              {val?.twoWayDistance} <span>KM</span>
            </div>
          </div>
          <div className="w-1/1 h-[0.5px] bg-line-color lg:hidden"></div>
          <div className="flex lg:flex-col lg:grow lg:items-center justify-between text-secondary-400 text-xs font-medium">
            <div className="lg:h-[45px] lg:flex justify-center items-center lg:bg-gray-200 lg:w-full lg:text-center">
              <p className="lg:grow lg:border-r-[0.5px] lg:border-r-disable-200">
                One-way Fare
              </p>
            </div>
            <div className="lg:h-[45px] lg:flex justify-center items-center">
              ₹ {val?.oneWayFare}
            </div>
          </div>
          <div className="flex lg:flex-col lg:grow lg:items-center justify-between text-secondary-400 text-xs font-medium">
            <div className="lg:h-[45px] lg:flex justify-center items-center lg:bg-gray-200 lg:w-full lg:text-center">
              <p className="lg:grow lg:border-r-[0.5px] lg:border-r-disable-200">
                Two-way Fare
              </p>
            </div>
            <div className="lg:h-[45px] lg:flex justify-center items-center">
              ₹ {val?.twoWayFare}
            </div>
          </div>
          <div className="flex lg:flex-col lg:grow lg:items-center justify-between text-secondary-400 text-xs font-medium">
            <div className="lg:h-[45px] lg:flex justify-center items-center lg:bg-gray-200 lg:w-full lg:text-center">
              <p className="lg:grow lg:border-r-[0.5px] lg:border-r-disable-200">
                {' '}
                Allowance
              </p>
            </div>
            <div className="lg:h-[45px] lg:flex justify-center items-center">
              ₹ {val?.allowance}
            </div>
          </div>
          <div className="flex lg:flex-col lg:grow lg:items-center justify-between text-secondary-400 text-sm font-semibold lg:font-semi">
            <div className="lg:h-[45px] lg:flex justify-center items-center lg:bg-gray-200 lg:w-full lg:text-center">
              Total
            </div>
            <div className="lg:h-[45px] lg:flex justify-center items-center">
              ₹ {val?.total}
            </div>
          </div>
          {status === 'Draft' ? (
            <div className="w-1/1 h-[0.5px] bg-line-color mt-2 lg:hidden "></div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="flex justify-between lg:hidden">
          {status === 'Draft' || status?.includes('Rejected') ? (
            <div>
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit();
                  dispatch(upadateExpenseIdCreationFormDataForEdit(val));
                  localStorage.setItem("isEdit" , true)
                }}
              >
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  className="text-primary"
                />
              </button>
            </div>
          ) : (
            <div></div>
          )}
          {status === 'Draft' ? (
            <div>
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete({
                    stpfcId: val?.stpfcId,
                    stpfcDetailid: val?.stpfcDetailid,
                  });
                }}
              >
                <FontAwesomeIcon icon={faTrash} className="text-primary" />
              </button>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
};

export default CardContainer;
