import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logoIcon from '/assets/images/logoIcon.png';
import uploadIcon from '/assets/images/uploadIcon.png';
import notificationDesktopIcon from '/assets/images/notificationIcon.png';
import Sidebar from '../sidebar/SideBar';
import { sideBarConfig } from '../../router/routersConfig';
import useNavigation from '../../hook/useNavigation';

import { Notification } from '../../pages/route';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NavBar = ({ isLoggedIn, isDrawerOpen, toggleDrawer }) => {
  const { showNavBar, showDrawerButton } = useNavigation(isLoggedIn);
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();
  const { data: notification } = useSelector((state) => state.notificationInfo);

  if (!showNavBar) {
    return null;
  }
  const hadlerUploadBill = () => {
    navigate('/upload-bills');
    localStorage.removeItem('uploadBill');
  };

  return (
    <>
      <nav
        className="hidden lg:block fixed top-0 left-0 w-full bg-territory-200 p-2"
        style={{ zIndex: 55 }}
      >
        <div className="flex min-h-[32px] items-center px-[40px] justify-between bg-territory-200">
          <Link to={'/home'}>
            <img
              className="h-[41px] w-[35px] cursor-pointer"
              src={logoIcon}
              alt="logo"
            />
          </Link>
          <div className="flex gap-4">
            <button onClick={hadlerUploadBill}>
              <img
                className="w-[21px] h-[21px]"
                src={uploadIcon}
                alt="upload"
              />
            </button>
            <button className="relative">
              <img
                className="w-[21px] h-[21px]"
                src={notificationDesktopIcon}
                alt="notification"
                onClick={() => setShowNotification(!showNotification)}
              />
              <span className="absolute -top-1 right-0 left-3 bg-primary text-white text-[8px] font-bold w-4 h-4 flex items-center justify-center rounded-full ">
                {notification?.notificationMessage?.length}
              </span>
              {showNotification && (
                <>
                  <div
                    className="fixed w-screen h-screen top-0 left-0 z-[99] "
                    onClick={() => setShowNotification(false)}
                  ></div>
                  <div className="absolute bg-white right-0 z-[100] top-[47px] w-[387px] h-[379px] shadow-lg overflow-y-auto">
                    <Notification isDesktop={true} />
                  </div>
                </>
              )}
            </button>
          </div>
        </div>
      </nav>

      {showDrawerButton && (
  <button
    onClick={toggleDrawer}
    className="fixed  z-50 w-full p-2 h-14  block lg:hidden text-gray-500 dark:text-gray-400 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 bg-gray-50 shadow-bottom"
  >
    <FontAwesomeIcon
      icon={faBars}
      size="xl"
      className='absolute left-4 top-4'
    />
  </button>
)}

      <Sidebar
        isOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        config={sideBarConfig}
      />
    </>
  );
};

export default NavBar;
