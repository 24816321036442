import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axiosInstance';
import {
  errorResponseParser,
  successResponseParser,
} from '../../utils/handlers';

export const deletenotificationDetails = createAsyncThunk(
  'deletenotificationDetails',
  async (notificationId, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(
        `/notificationView?notificationId=` + notificationId
      );
      return successResponseParser(res);
    } catch (error) {
      return rejectWithValue(errorResponseParser(error, dispatch));
    }
  }
);

export const notificationDetails = createAsyncThunk(
  'notificationDetails',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const employeeId = JSON.parse(
        localStorage.getItem('userData')
      )?.employeeId;

      const res = await axios.get(`/getNotification`, {
        params: { employeeId: employeeId },
      });
      return successResponseParser(res);
    } catch (error) {
      return rejectWithValue(errorResponseParser(error, dispatch));
    }
  }
);

export const notificationInfo = createSlice({
  name: 'notificationInfo',
  initialState: {
    isLoading: false,
    data: null,
    apiError: '',
    isNotificationDelete: false,
  },
  reducers: {
    resetDeleteModal: (state) => {
      state.isNotificationDelete = false;
    },
  },
  extraReducers: (builder) => {
    // notification get
    builder.addCase(notificationDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(notificationDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(notificationDetails.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
    });
    // notification delete api
    builder.addCase(deletenotificationDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(deletenotificationDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isNotificationDelete = true;
    });
    builder.addCase(deletenotificationDetails.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
    });
  },
});

export const { resetDeleteModal } = notificationInfo.actions;
export default notificationInfo.reducer;
