import React, { useEffect, useState, useRef } from 'react';
import Dropdown from '../../components/dropdown/Dropdown';
import Textarea from '../../components/textarea/Textarea';
import Button from '../../components/button/Button';
import { priodSatate } from '../../utils/Constant';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  fetchActivityModifyRoute,
  fetchCityListModifyRoute,
  getExpenseActivityModifyRoute,
  getCityNameModifyRoute,
  getFromCityModifyRoute,
  fetchFromCityModifyRoute,
  setModifySubmit,
} from '../../redux/slice/modifyRouteSlice';
import { useNavigate } from 'react-router-dom';
import { userInfo } from '../../utils/Constant';
import deleteIcon from '/assets/images/deleteIcon.png';

const { employeeId, companyId } = userInfo;

const ModifyRoutes = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cityName = useSelector(getCityNameModifyRoute);
  const activityName = useSelector(getExpenseActivityModifyRoute);
  const fromCityName = useSelector(getFromCityModifyRoute);
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [toCityNowFromCity, setToCityNowFromCity] = useState('');
  const [modifyRoutesData, setModifyRoutesData] = useState({});
  const [errors, setErrors] = useState({
    isPeriod: false,
    isActivity: false,
    isToCity: false,
  });
  const [data, setData] = useState({
    isStay: '',
    remarks: '',
    tblRoute: [],
  });
  const [currentDropdown, setCurrentDropdown] = useState(null);
  const modalRef = useRef(null);

  const newFromCity =
    tableData.length === 0 ? fromCityName?.cityName : toCityNowFromCity;

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchActivityModifyRoute());
      dispatch(fetchCityListModifyRoute());
      dispatch(fetchFromCityModifyRoute());
    }
  }, [isOpen, dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleAdd = async (event) => {
    event.preventDefault();

    const { period, activity, toCity } = modifyRoutesData;
    const { expenseId, activityDate } = JSON.parse(localStorage.getItem('SelectedExpenseData')) || {};
    let newFromCity = tableData.length === 0 ? fromCityName?.cityName : toCityNowFromCity;
    const newToCity = cityName.find((item) => item.cityId === toCity)?.cityName || '';
    const newPeriod = priodSatate.find((item) => item.value === period)?.periodName || '';
    const newActivity = activityName.find((item) => item.activityTypeId === activity)?.activityName || '';

    const errors = {
      isPeriod: !newPeriod ? 'Please select period' : false,
      isActivity: !newActivity ? 'Please select activity' : false,
      isToCity: !newToCity ? 'Please select to city' : false,
    };
    setErrors((prev) => ({ ...prev, ...errors }));

    if (newPeriod && newActivity && newToCity && newFromCity) {
      setTableData((prev) => [
        ...prev,
        { period: newPeriod, activity: newActivity, fromCity: newFromCity, toCity: newToCity },
      ]);

      setData((prev) => ({
        ...prev,
        employeeId,
        expenseId,
        companyId,
        period,
        activity: String(activity),
        activityDate,
        tblRoute: [
          ...prev.tblRoute,
          {
            Slno: String(prev.tblRoute.length + 1),
            ActTypeId: String(prev.tblRoute.length + 1),
            Period: String(period),
            FromCity: tableData.length === 0 ? String(fromCityName.cityId) : cityName.find((item) => item.cityName === toCityNowFromCity)?.cityId,
            ToCity: String(toCity),
          },
        ],
      }));

      setToCityNowFromCity(newToCity);
      setModifyRoutesData((prev) => ({
        ...prev,
        period: '',
        activity: '',
        toCity: '',
        fromCity: '',
      }));

      if (!showTable) {
        setShowTable(true);
      }
    }
  };

  const handleDeleteRow = (index) => {
    setTableData((prev) => prev.filter((_, i) => i !== index));
    setShowTable(tableData.length > 1);
  };


  const handleModifyRouteSelector = (data) => {
    setModifyRoutesData((prev) => ({ ...prev, ...data }));
  };

  useEffect(() => {}, [modifyRoutesData]);

  const handlerChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    const errors = {
      isStay: !data.isStay ? 'Please select Stay or not' : false,
      remarks: !data.remarks ? 'Please select Remark' : false,
    };

    setErrors((prev) => ({ ...prev, ...errors }));
    if (

      (data.isStay && data.remarks && tableData.length > 0)
    ) {
      dispatch(setModifySubmit(data));
      setTimeout(() => {
        onClose();
      }, 2000);
    }
  };

  const handleDropdownToggle = (dropdownName) => {
    setCurrentDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };
  return (
    <div>
      {/* Overlay */}
      <div
        className={`fixed inset-0 bg-black/50   ${
          isOpen ? 'opacity-100  bg-blur z-50' : 'opacity-0 pointer-events-none'
        }`}
      />

      {/* Modal Container */}
      <div
        ref={modalRef}
        className={`fixed z-50 transition-all duration-300 ${
          isOpen ? ' opacity-100' : 'opacity-0 pointer-events-none'
        }
          lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-[45%] ${showTable && tableData?.length > 0 ? 'lg:h-[91%] lg:w-[70%] z-[60]' : 'lg:h-[68%] lg:w-[74%] ' }  lg:max-w-7xl  lg:rounded-lg
          bottom-0 left-0 w-full h-[90%] rounded-t-3xl`}
      >
        <div className="bg-white h-full lg:rounded-lg shadow-xl flex flex-col lg:justify-between smAndMd:rounded-t-3xl">
        {/* Header */}
          <div className="p-2  relative">
            <button
              className="absolute top-4 right-5 text-primary text-4xl"
              onClick={onClose}
            >
              &times;
            </button>
          </div>

          {/* Content */}
          <div className="flex-1 p-6  overflow-auto lg:overflow-hidden">
            <div className="space-y-4  lg:flex lg:flex-col lg:justify-between">
              {/* Form Fields in Single Line */}
              <div className="grid grid-cols-1 lg:grid-cols-4 smAndMd:grid-cols-2  gap-6">
                {/* Period Dropdown */}
                <div>
                  <label className="block text-baseMd text-gray-700 pb-2">
                    Period
                  </label>
                  <Dropdown
                    label="Select"
                    name="period"
                    value={modifyRoutesData.period}
                    options={priodSatate}
                    attributeValue="value"
                    attributeName="periodName"
                    onChange={handleModifyRouteSelector}
                    isOpen={currentDropdown === 'period'}
                    onDropdownToggle={handleDropdownToggle}
                  />
                  {errors.isPeriod && (
                    <div className="text-red-500 mt-1">{errors.isPeriod}</div>
                  )}
                </div>

                {/* Activity Dropdown */}
                <div>
                  <label className="block text-baseMd text-gray-700 pb-2">
                    Activity
                  </label>
                  <Dropdown
                    label="Select"
                    name="activity"
                    value={modifyRoutesData.activity}
                    options={activityName}
                    attributeValue="activityTypeId"
                    attributeName="activityName"
                    onChange={handleModifyRouteSelector}
                    isOpen={currentDropdown === 'activity'}
                    onDropdownToggle={handleDropdownToggle}
                  />
                  {errors.isActivity && (
                    <div className="text-red-500 mt-1">{errors.isActivity}</div>
                  )}
                </div>

                {/* From City Dropdown */}
                <div>
                  <label className="block text-baseMd text-gray-700 pb-2">
                    From City
                  </label>
                  <Dropdown
                    isDisable={true}
                    disabledValue={newFromCity}
                    name="fromCity"
                    value={fromCityName?.cityName}
                    attributeName="cityName"
                    options={fromCityName}
                    attributeValue="cityId"
                    onChange={handleModifyRouteSelector}
                  />
                </div>

                {/* To City Dropdown */}
                <div>
                  <label className="block text-baseMd text-gray-700 pb-2">
                    To City
                  </label>
                  <Dropdown
                    label="Select"
                    name="toCity"
                    value={modifyRoutesData.toCity}
                    options={cityName}
                    attributeValue="cityId"
                    attributeName="cityName"
                    onChange={handleModifyRouteSelector}
                    isOpen={currentDropdown === 'toCity'}
                    onDropdownToggle={handleDropdownToggle}
                  />
                  {errors.isToCity && (
                    <div className="text-red-500 mt-1">{errors.isToCity}</div>
                  )}
                </div>
              </div>
            {/* <div className="flex flex-col lg:flex-row lg:justify-end"> */}
              {/* Add Button */}
              <div className="lg:mb-0  lg:flex lg:justify-end lg:w-full">
                <button
                  className="smAndMd:w-full lg:w-36 lg:h-10 px-4 py-2 text-primary border border-primary   rounded-3xl"
                  onClick={handleAdd}
                >
                  Add
                </button>
              </div>
            {/* </div> */}


            {showTable && tableData?.length > 0 && (
                <div className="max-h-[14vh]">

                {/* Right Side - Table */}
                <div className="lg:col-span-9">
                  {/* {showTable && tableData?.length > 0 && ( */}
                    <div className="border rounded-lg">
                      <div className="lg:max-h-[40vh] h-[18vh] overflow-y-auto">
                        <table className="w-full">
                          <thead className="bg-gray-200 top-0">
                            <tr>
                              {[
                                'Sl No',
                                'Period',
                                'Activity',
                                'From City',
                                'To City',
                                'Action',
                              ].map((header) => (
                                <th
                                  key={header}
                                  className="px-4 py-3 text-left text-sm  font-bold text-gray-700"
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {tableData.map((item, index) => (
                              <tr key={index} className="hover:bg-gray-50">
                                <td className="px-4 py-3">{index + 1}</td>
                                <td className="px-4 py-3">{item.period}</td>
                                <td className="px-4 py-3">{item.activity}</td>
                                <td className="px-4 py-3">{item.fromCity}</td>
                                <td className="px-4 py-3">{item.toCity}</td>
                                <td className="px-4 py-3">
                                  <button
                                    onClick={() => handleDeleteRow(index)}
                                  >
                                    <img
                                      src={deleteIcon}
                                      alt="delete"
                                      className="w-5 h-5"
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                </div>
              </div>
               )}

              {/* Stay and Remarks in Single Line */}
              <div className={`lg:flex lg:justify-start lg:space-x-[4.5rem] ${tableData?.length > 0 ? 'lg:pt-4' : ''}`}>
                <div className='pt-8 lg:pt-0'>
                  <p className="text-base lg:text-baseMd text-gray-700">Stayed Over Night ?</p>
                  <div className="flex space-x-6 mt-2">
                    {['No', 'Yes'].map((label) => (
                      <div key={label} className="flex items-center space-x-2">
                        <input
                          type="radio"
                          id={label}
                          name="isStay"
                          value={label}
                          checked={data.isStay === label}
                          onChange={handlerChange}
                          className="form-radio text-[#514D4C]"
                        />
                        <label htmlFor={label} className="text-[#514D4C]" style={{color: '#514D4C'}}>
                          {label}
                        </label>
                      </div>
                    ))}
                  </div>
                  {errors.isStay && (
                    <div className="text-red-500 mt-1">{errors.isStay}</div>
                  )}
                </div>

                <div className='smAndMd:mt-4 lg:pl-5'>
                  <label className="text-base lg:text-baseMd text-gray-700 pb-4">
                    Remarks
                  </label>
                  <Textarea
                    name="remarks"
                    value={data.remarks}
                    onChange={handlerChange}
                    textAreaClassName="h-24 lg:h-20 w-full border rounded-lg p-2 resize-none mt-4 focus:outline-none focus:ring-2 focus:ring-primary placeholder:text-baseMd"
                    placeholder="Enter your remarks here..."
                  />
                  {errors.remarks && (
                    <div className="text-red-500 mt-1">{errors.remarks}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="smAndMd:border-t bg-white p-4 lg:pb-2 lg:pr-6 flex justify-end gap-4 smAndMd:justify-evenly lg:rounded-b-lg">
            <Button
              label="Close"
              btTextColor="text-primary"
              btBgColor="bg-white"
              onClick={onClose}

            />
            <Button
              label="Submit"
              btTextColor="text-white"
              btBgColor="bg-primary"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifyRoutes;