import React from 'react';

const Loader = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <div
        className="w-16 h-16 border-8 border-gray-300 rounded-full animate-spin"
        style={{ borderTopColor: '#A04D95' }}
      ></div>
    </div>
  );
};

export default Loader;


