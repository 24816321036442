import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import CardContainer from './CardContainer';
import Btn from './Btn';
import FormContainer from './FormContainer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSfcTravelingExpences } from '../../redux/slice/sfcTravelingExpences';
import { fetchSfcFormFilters } from '../../redux/slice/sfcFormFilters';
import {
  clearState,
  updateSaveSuccess,
} from '../../redux/slice/sfcSaveTravelExpence';
import { getSfcTravelExpenseFormInitialState } from '../../redux/slice/sfcTravelExpenseForm';
import {
  postSfcSubmitTravelExpence,
  updateSubmitSuccess,
} from '../../redux/slice/sfcCreationSubmit';
import SideBar from '../../components/sidebar/SideBar';
import Spinner from '../../components/Spinner';
import SuccessfullPopup from './SuccessfullPopup';
import { sfcUserDetails } from '../../redux/slice/sfcUserBasicInfo';
import {
  getInitialDeleteExpense,
  sfcTravelExpenceDelete,
} from '../../redux/slice/sfcTravelExpenseDelete';
import { Link } from 'react-router-dom';
import ConfirmationPopup from './ConfirmationPopup';
import { notificationDetails } from '../../redux/slice/notification';

const Sfc = () => {
  const [stepOne, setStepOne] = useState(false);
  const [detailedViewID, setDetailedViewId] = useState(null);
  const { isLoading: isFilterLoading, transport } = useSelector(
    (state) => state.sfcFormFilters
  );
  const { isSuccess: isSaveSuccess, data: sfcSaveTravelExpence = {} } =
    useSelector((state) => state.sfcSaveTravelExpence);
  const { data: sfcTravelingExpences, isLoading: isLoadingExpences } =
    useSelector((state) => state.sfcTravelingExpences);
  const { isSubmit: isSubmitSuccess, isLoading: isLoadingSubmit } = useSelector(
    (state) => state.sfcCreationSubmit
  );
  const {
    data: sfcTravelingExpenseDelete,
    isSuccess: isDeleteSuccess,
    isLoading: isDeleting,
  } = useSelector((state) => state.sfcDeleteExpence);
  const sfcFinalSubmit = useSelector((state) => state.sfcCreationSubmit);
  const { data: userDetails } = useSelector((state) => state.sfcUserBasicInfo);
  const [toggle, setToggle] = useState('');
  const dispatch = useDispatch();
  const { isEditingMode } = useSelector((state) => state.sfcTravelExpenseForm);
  const [isDisableOperation, setIsDisableOperation] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const { isNotificationDelete } = useSelector(
    (state) => state.notificationInfo
  );

  const onClickHandler = (val) => {
    setDetailedViewId(null);
    if (toggle) {
      setToggle((prev) => (prev === val ? '' : val));
    } else {
      setToggle(val);
    }
  };

  useEffect(() => {
    dispatch(notificationDetails());
  }, [isNotificationDelete]);

  useEffect(() => {
    if (userDetails) {
      const { employeeId, companyId } = userDetails;
      dispatch(fetchSfcFormFilters({ employeeId, companyId }));
    }
  }, [userDetails]);

  useEffect(() => {
    dispatch(fetchSfcTravelingExpences());
    dispatch(sfcUserDetails());
  }, [sfcTravelingExpenseDelete, sfcFinalSubmit]);

  useEffect(() => {
    if (sfcTravelingExpences?.length) {
      setIsDisableOperation(
        sfcTravelingExpences.some(
          (expense) =>
            expense.status === 'Submitted' ||
            expense.status?.includes('Rejected')
        )
      );
    }
  }, [sfcTravelingExpences]);

  useEffect(() => {
    const activeForm = localStorage.getItem('active')
      ? Number(localStorage.getItem('active'))
      : null;
    if (activeForm) {
      setStepOne(true);
    }
  }, []);

  return (
    <div className="h-lvh lg:pt-[57px] flex flex-col justify-between box-border lg:relative">
      <ConfirmationPopup
        message={'Are you sure, you want to delete?'}
        show={deleteId !== null}
        onDenied={setDeleteId.bind(this, null)}
        onProceed={() => {
          dispatch(sfcTravelExpenceDelete(deleteId));
          setDeleteId(null);
        }}
      />{' '}
      {isSubmitSuccess ? (
        <SuccessfullPopup
          displayText={'Submitted'}
          onClose={() => dispatch(updateSubmitSuccess(false))}
        />
      ) : null}
      {isSaveSuccess ? (
        <SuccessfullPopup
          onClose={() => dispatch(updateSaveSuccess(false))}
          displayText={isEditingMode ? 'Updated' : 'Added'}
        />
      ) : null}
      {isDeleteSuccess ? (
        <SuccessfullPopup
          onClose={() => dispatch(getInitialDeleteExpense())}
          displayText="Deleted"
        />
      ) : null}
      {!stepOne && (
        <div className="h-full flex flex-col box-border">
          <div className="flex items-center justify-between px-3 py-5">
            {/* <SideBar /> */}
            <Link to="/home" className="grow lg:hidden">
              <FontAwesomeIcon icon={faAngleLeft} color="#A04D95" size="lg" />
            </Link>
            <p className="text-primary font-semibold text-lg smAndMd:grow text-center">
              {detailedViewID === null ? 'SFC' : 'SFC Detailed'}
            </p>
            <div className="grow text-end lg:hidden">
              {userDetails?.isAddEnable ? (
                <button
                  onClick={() => {
                    setStepOne(true);
                    dispatch(clearState());
                    dispatch(getSfcTravelExpenseFormInitialState());
                  }}
                  className="mr-1"
                >
                  {transport.length ? (
                    <FontAwesomeIcon
                      icon={faCirclePlus}
                      color={'#A04D95'}
                      size={'xl'}
                    />
                  ) : null}
                </button>
              ) : null}
            </div>
          </div>

          <div className="p-3 flex gap-6 items-center  max-w-full">
            <div className="grow overflow-scroll no-scroll">
              <div className="flex items-center gap-3">
                {transport.length
                  ? transport.map((val, index) => {
                      return (
                        <Btn
                          key={index}
                          text={val.transportName}
                          onClick={onClickHandler.bind(this, val.transportName)}
                          index={index}
                          isSelected={toggle === val.transportName}
                        />
                      );
                    })
                  : null}
              </div>
            </div>

            <div className="grow text-end smAndMd:hidden">
              {userDetails?.isAddEnable ? (
                <button
                  onClick={() => {
                    setStepOne(true);
                    dispatch(clearState());
                    dispatch(getSfcTravelExpenseFormInitialState());
                  }}
                  className="mr-1"
                >
                  {transport.length ? (
                    <FontAwesomeIcon
                      icon={faCirclePlus}
                      color={'#A04D95'}
                      size={'xl'}
                    />
                  ) : null}
                </button>
              ) : null}
            </div>
          </div>

          <div
            className="grow overflow-scroll lg:overflow-visible no-scroll sm:p-4"
            style={{ rowGap: '2px', flexGrow: 1 }}
          >
            <div className="w-1/1 h-[0.5px] bg-line-color -mt-3 mb-4 smAndMd:hidden "></div>
            {(isLoadingExpences ||
              isFilterLoading ||
              isDeleting ||
              isLoadingSubmit) &&
            !(isSaveSuccess || isDeleteSuccess || isSubmitSuccess) ? (
              <Spinner transparent />
            ) : sfcTravelingExpences?.length ? (
              <div className="flex smAndMd:bg-gray-300 flex-col gap-[1px] lg:gap-4 pt-[1px] pb-[1px]">
                {sfcTravelingExpences?.map((val, index) => {
                  return !toggle || val.transportName === toggle ? (
                    <CardContainer
                      display={detailedViewID === val.stpfcDetailid}
                      setDetailedViewId={setDetailedViewId}
                      onEdit={() => setStepOne(true)}
                      status={val?.status}
                      key={index}
                      setDisplayData={sfcSaveTravelExpence}
                      val={val}
                      onDelete={setDeleteId}
                    />
                  ) : null;
                })}
              </div>
            ) : (
              <div className="text-primary flex justify-center items-center font-medium h-full ">
                No Data Found
              </div>
            )}
          </div>
          <div className="w-1/1 h-[0.5px] bg-line-color smAndMd:hidden mx-4" style={{marginBottom:"-1px"}}></div>
          {userDetails?.isSubmitEnable ? (
            <div className="w-full flex justify-end items-center bg-white p-5  smAndMd:shadow-top  py-[15px] smAndMd:pb-16" style={{marginTop:"1px"}}>
              <button
                className="smAndMd:w-full bg-primary text-white rounded-full p-3 px-12 font-bold"
                onClick={() => {
                  const stpfcDetailIds = sfcTravelingExpences
                    .filter((expences) => expences.status === 'Draft')
                    .map((expences) => expences.stpfcDetailid);

                  if (userDetails && stpfcDetailIds.length) {
                    const {
                      employeeId,
                      companyId,
                      stpfcId,
                      effectiveFromDate: effectiveDate,
                    } = userDetails;
                    dispatch(
                      postSfcSubmitTravelExpence({
                        stpfcDetailIds,
                        employeeId,
                        companyId,
                        effectiveDate,
                        stpfcId,
                      })
                    );
                  }
                }}
              >
                Submit
              </button>
            </div>
          ) : null}
        </div>
      )}
      {stepOne && <FormContainer onBack={() => setStepOne(false)} />}
    </div>
  );
};

export default Sfc;
