import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from '../../components/select';
import Counter from '../../components/Counter/Counter';
import Input from '../../components/input';
import Spinner from '../../components/Spinner';
import Datepicker from '../../components/datepicker';

const formatDate = (date) => {
  const [d1, d2, d3] = date.split('-');
  return d1.length === 2 ? `${d3}-${d2}-${d1}` : date;
};

const getTodayDate = (days = 0) => {
  const d = new Date();
  const date = d.getDate() + days;
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  return `${year}-${month}-${date > 9 ? date : '0' + date}`;
};

const SfcFormOne = ({ formValues = {}, onChangeHandler, errors }) => {
  const { areaRange: employeeType, data: userInfo } = useSelector(
    (state) => state.sfcUserBasicInfo
  );
  const { isLoading, fromCities, toCities, territory, transport, categories } =
    useSelector((state) => state.sfcFormFilters);

  useEffect(() => {
    if (employeeType && employeeType === 'T' && territory[0]?.territoryId) {
      onChangeHandler({
        target: { name: 'fromTerritoryId', value: territory[0].territoryId },
      });
      onChangeHandler({
        target: { name: 'toTerritoryId', value: territory[0].territoryId },
      });
    }
  }, [employeeType, territory, userInfo]);
  useEffect(() => {
    if (userInfo?.effectiveFromDate && !formValues.effectiveDate) {
      onChangeHandler({
        target: { name: 'effectiveDate', value: userInfo.effectiveFromDate },
      });
    }
  }, [userInfo]);

  return (
    <div className="">
      {isLoading && <Spinner text="Loading" transparent />}
      <div className="flex justify-between px-2 items-center pb-3">
        <p className="text-secondary-400 grow text-fieldset pt-3 font-medium">
          Journey
        </p>
        <Datepicker
          className="p-[2px] m-0 text-[14px] pt-2 bg-white"
          type="date"
          varient="animated"
          onChange={onChangeHandler}
          label={'Effective Date'}
          error={errors?.effectiveDate}
          name="effectiveDate"
          value={formatDate(formValues?.effectiveDate)}
          disabled={
            userInfo?.effectiveFromDate
              ? !(
                  userInfo?.status === 'A' ||
                  userInfo?.status === 'R' ||
                  userInfo?.effectiveFromDateFlag === 1
                )
              : false
          }
          pattern="dd-mm-yyyy"
          min={
            userInfo?.effectiveFromDate
              ? formatDate(userInfo?.effectiveFromDate)
              : getTodayDate(1)
          }
          labelClassName="text-[12px] effective-input"
        />
      </div>
      {/* <div className="h-0.5 bg-gray-200"></div> */}
      <div className="w-1/1 h-[0.5px] bg-line-color mx-2 lg:mb-3"></div>
      <div className="lg:w-[90%] lg:grid lg:grid-cols-2 lg:max-w-5xl lg:gap-y-2">
        <Select
          options={territory}
          disabled={employeeType === 'T'}
          label={employeeType !== 'T' ? 'From Territory' : 'Territory'}
          labelAttri={'territoryName'}
          valueAttri={'territoryId'}
          onChange={onChangeHandler}
          value={formValues.fromTerritoryId}
          name="fromTerritoryId"
          error={errors.fromTerritoryId}
        />
        <Select
          disabled={!formValues.fromTerritoryId}
          options={fromCities}
          label={'From City'}
          labelAttri={'cityName'}
          valueAttri={'cityId'}
          onChange={onChangeHandler}
          value={formValues.fromCityId}
          name={'fromCityId'}
          error={errors.fromCityId}
        />
        {employeeType !== 'T' && (
          <Select
            options={territory}
            label={'To Territory'}
            labelAttri={'territoryName'}
            valueAttri={'territoryId'}
            onChange={onChangeHandler}
            value={formValues.toTerritoryId}
            name={'toTerritoryId'}
            error={errors.toTerritoryId}
          />
        )}

        <Select
          disabled={!formValues.toTerritoryId}
          options={employeeType === 'A' ? toCities : fromCities}
          label={'To City'}
          labelAttri={'cityName'}
          valueAttri={'cityId'}
          onChange={onChangeHandler}
          value={formValues.toCityId}
          name={'toCityId'}
          error={errors.toCityId}
        />
        {/* Mobile */}
        <div className="flex gap-1 lg:hidden">
          <div className="w-1/2">
            <Select
              options={categories}
              label={'Category'}
              labelAttri={'categoryName'}
              valueAttri={'categoryId'}
              onChange={onChangeHandler}
              value={formValues.categoryId}
              name={'categoryId'}
              error={errors.categoryId}
            />
          </div>
          <div className="w-1/2">
            <Select
              options={transport}
              label={'Transport'}
              labelAttri={'transportName'}
              valueAttri={'transportId'}
              onChange={onChangeHandler}
              value={formValues.transportId}
              name={'transportId'}
              error={errors.transportId}
            />
          </div>
        </div>
        {/* Desktop */}
        <div className="smAndMd:hidden">
          <Select
            options={categories}
            label={'Category'}
            labelAttri={'categoryName'}
            valueAttri={'categoryId'}
            onChange={onChangeHandler}
            value={formValues.categoryId}
            name={'categoryId'}
            error={errors.categoryId}
          />
        </div>
        <div className="smAndMd:hidden">
          <Select
            options={transport}
            label={'Transport'}
            labelAttri={'transportName'}
            valueAttri={'transportId'}
            onChange={onChangeHandler}
            value={formValues.transportId}
            name={'transportId'}
            error={errors.transportId}
          />
        </div>
        <div className="smAndMd:hidden"></div>
        <div className="flex justify-between mt-5 p-5 lg:px-2 lg:py-0 max-w-lg">
          <div>
            <label htmlFor="no of doctors">No of Doctors</label>
            <Counter
              value={formValues.numberOfDoctors}
              min={0}
              onChange={(action) =>
                onChangeHandler({
                  target: { name: 'numberOfDoctors', value: action.value },
                })
              }
            />
          </div>
          <div>
            <label htmlFor="no of Chemists">No of Chemists</label>
            <Counter
              value={formValues.numberOfChemist}
              min={0}
              onChange={(action) =>
                onChangeHandler({
                  target: { name: 'numberOfChemist', value: action.value },
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SfcFormOne;
