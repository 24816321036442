import React, { useState, useEffect, useRef } from 'react';
import uploadBillPrimaryIcon from '/assets/images/uploadBillPrimaryIcon.png';
import PreviewModal from './PreviewModal';
import Dropdown from '../../components/dropdown/Dropdown';
import { useNavigate } from 'react-router-dom';
import PopupModal from '../../components/modal/PopupModal';
import { dateFormatYYYYMMDD, formatDate } from '../../utils/utilFun';

import { useSelector, useDispatch } from 'react-redux';
import {
  fetchExpenseType,
  getAllExpenseType,
  expensedetailUpdate,
  setExpenseSubmit,
  getExpenseSubmitResponse,
} from '../../redux/slice/cameraSlice';
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router-dom';

import { userInfo } from '../../utils/Constant';
const { employeeId, companyId } = userInfo;
//images
import calenderGrayUploadBillIcon from '/assets/images/calenderGrayUploadBillIcon.png';

const UploadBills = () => {
  const [currentDropdown, setCurrentDropdown] = useState(null);
  const [showUpdateSuccessModal, setShowUpdateSuccessModal] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const dropdownRef = useRef(null);
  const { state } = useLocation();
  const datePickerRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const allExpenseType = useSelector(getAllExpenseType);
  console.log('allExpenseType', allExpenseType);

  const getUnreportedData =
    JSON.parse(localStorage.getItem('uploadBill')) || '';

  const { isLoading, expenseSubmitResponse, apiError, errorMessage } =
    useSelector(getExpenseSubmitResponse);

  const defaultDate = getUnreportedData?.invoiceDate
    ? getUnreportedData?.invoiceDate
    : null;
  const {
    invoiceNumber,
    expenseType,
    expenseOrSundry,
    amount,
    invoiceDate,
    imageBase64,
  } = getUnreportedData;

  const [formData, setFormData] = useState({
    selectedFile: imageBase64 || null,
    ImageBase64: imageBase64 || '',
    expenseType: expenseType || '',
    invoiceNumber: invoiceNumber || '',
    invoiceDate: invoiceDate || '',
    invoiceAmount: amount || '',
    totalAmount: amount || '',
    expenseOrSundry: expenseOrSundry || '',
  });

  const convertDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString?.split('-');
    return `${year}-${month}-${day}`;
  };

  const formattedDate = convertDate(defaultDate);
  const [startDate, setStartDate] = useState(
    formattedDate ? new Date(formattedDate) : null
  );

  //Close Dropdown on outside click
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCurrentDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setCurrentDropdown]);

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
  };

  const handleDownload = () => {
    if (formData.selectedFile?.preview || state) {
      const link = document.createElement('a');
      link.href =
        formData.selectedFile.preview ||
        'data:image/png;base64,' + formData.ImageBase64;
      link.download = formData.selectedFile.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormData((prevData) => ({
            ...prevData,
            selectedFile: {
              name: file.name,
              size: file.size,
              preview: URL.createObjectURL(file),
            },
            ImageBase64: reader.result,
          }));
        };
        reader.readAsDataURL(file);
      } else {
        alert('Only JPEG and PNG files are allowed');
      }
    }
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    setErrors((prevErrors) => ({
      ...prevErrors,
      invoiceDate: date ? '' : 'Date field cannot be empty',
    }));
  };

  const handleExpenseTypeChange = (data) => {
    let expenseOrSundry = allExpenseType
      .filter((item) => item.expense_Type === data.expense_Type)
      .map((item) => item.expenseOrSundry)
      .join('');
    setFormData((prevDetails) => ({
      ...prevDetails,
      expenseOrSundry,
      expenseType: data.expense_Type,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, expenseType: '' }));
  };

  useEffect(() => {
    dispatch(fetchExpenseType());
  }, [dispatch]);

  const handleChange = (key, value) => {
    setFormData((prevData) => ({ ...prevData, [key]: value }));
  };

  const validateFields = () => {
    const errors = {};
    if (!formData.expenseType) errors.expenseType = true;
    if (!formData.invoiceNumber) errors.invoiceNumber = true;
    if (!formData.invoiceDate || !startDate) errors.invoiceDate = true;
    if (!formData.invoiceAmount) errors.invoiceAmount = true;
    if (!formData.selectedFile) errors.selectedFile = true;
    return errors;
  };

  const handleAttach = async () => {
    formData.invoiceDate = dateFormatYYYYMMDD(startDate);
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const {
        expenseType,
        invoiceNumber,
        invoiceDate,
        invoiceAmount,
        expenseOrSundry,
        ImageBase64,
      } = formData;
      if (
        expenseType &&
        invoiceNumber &&
        invoiceDate &&
        invoiceAmount &&
        expenseOrSundry &&
        ImageBase64
      ) {
        const billUploadData = {
          UploadDate: invoiceDate,
          ExpenseType: expenseType,
          invoiceNumber: invoiceNumber,
          invoiceDate: invoiceDate,
          fare: invoiceAmount,
          expenseOrSundry: expenseOrSundry,
          ImageBase64: ImageBase64,
        };

        try {
          const response = await dispatch(setExpenseSubmit(billUploadData));

          if (!response.error) {
            setShowSuccessModal(true);
            setTimeout(() => {
              navigate('/un-reported-expenses');
            }, 1000);
          } else {
            setShowUpdateSuccessModal(response?.payload?.message);
            setShowSuccessModal(true);
          }
        } catch (error) {
          console.error('Error submitting expense:', error);
          setShowSuccessModal(true);
        }
      }
      setErrors({});
    }
  };

  const handleDropdownToggle = (dropdownName) => {
    setCurrentDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const handleUpdate = async () => {
    try {
      const unReportAttachmentIdAPI = getUnreportedData?.unReportExpenseId;
      if (unReportAttachmentIdAPI) {
        const convertedDateFormat = startDate ? formatDate(startDate) : '';

        const billUploadData = {
          UploadDate: convertedDateFormat,
          ExpenseType: formData.expenseType,
          invoiceNumber: formData.invoiceNumber,
          invoiceDate: convertedDateFormat,
          Amount: formData.invoiceAmount,
          expenseOrSundry: formData.expenseOrSundry,
          EmployeeId: employeeId,
          companyId: companyId,
        };

        let updateResponse = await dispatch(
          expensedetailUpdate({ data: billUploadData, unReportAttachmentIdAPI })
        );
        console.log('updateResponse', updateResponse);
        setShowUpdateSuccessModal(
          updateResponse?.payload?.unreportedUpdateStatus
        );
        setFormData({
          selectedFile: null,
          ImageBase64: '',
          expenseType: '',
          invoiceNumber: '',
          invoiceDate: '',
          invoiceAmount: '',
          totalAmount: '',
          expenseOrSundry: '',
        });
        localStorage.removeItem('uploadBill');
        setStartDate(null);

        setShowSuccessModal(true);
        setTimeout(() => {
          navigate('/un-reported-expenses');
        }, 1000);
      } else {
        console.log('Error updating expense:', errorMessage);
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error('Error updating expense:', error);
    }
  };

  const handlerCancel = () => {
    localStorage.removeItem('uploadBill');
    navigate(-1);
  };

  const handlerNavigate = (path) => {
    localStorage.removeItem('uploadBill');
    setFormData({
      selectedFile: null,
      ImageBase64: '',
      expenseType: '',
      invoiceNumber: '',
      invoiceDate: '',
      invoiceAmount: '',
      totalAmount: '',
      expenseOrSundry: '',
    });
    navigate(path);
  };

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus();
    }
  };


  return (
    <div className=" flex flex-col p-4 min-h-screen">
      <p className="text-baselg font-semibold text-[#A15CA4] pt-12 pb-2">
        Upload Bill
      </p>
      <div className="h-[0.5px] w-full bg-line-color"></div>
      <div className="flex flex-col md:flex-row  md:items-start pt-4 lg:mb-4">
        <div className="w-full lg:w-3/5 mb-6 md:mb-0 lg:pr-4">
          {!formData.selectedFile ? (
            <div
              className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center min-h-[21.5rem] flex items-center justify-center"
              onClick={() => document.getElementById('file-upload')?.click()}
            >
              <div className="flex flex-col items-center justify-center">
                <img
                  src={uploadBillPrimaryIcon}
                  alt="Upload Icon"
                  className="h-6 w-6 inline-block mb-2"
                />
                <input
                  type="file"
                  id="file-upload"
                  className="hidden"
                  accept="image/jpeg, image/png"
                  onChange={handleFileChange}
                />
                <button className="bg-[#A15CA4] text-white px-6 py-2 rounded-3xl hover:bg-[#8f4f91] transition-colors">
                  Browse
                </button>
                <p className="mt-2 text-sm text-gray-600">
                  Attach bills/receipts from your system
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="flex items-center space-x-4 border p-4 rounded-md relative">
                {formData.selectedFile.preview || state || getUnreportedData ? (
                  <img
                    src={
                      state || getUnreportedData
                        ? 'data:image/png;base64,' + formData.ImageBase64
                        : formData.selectedFile.preview
                    }
                    alt="Attachment Preview"
                    className="h-16 w-16 object-cover rounded-md"
                  />
                ) : (
                  <div className="h-12 w-12 flex items-center justify-center bg-gray-200 rounded-md">
                    <span className="text-gray-500">File</span>
                  </div>
                )}
                <span className="text-sm text-gray-800">
                  {formData.selectedFile.name || ''}
                </span>
                <div className="absolute bottom-2 left-20">
                  <span className="text-sm text-gray-500">
                    {formData?.selectedFile?.size
                      ? formData?.selectedFile?.size / 1000 + 'KB'
                      : ''}
                  </span>
                </div>

                <div className="flex w-full justify-end space-x-4 absolute right-4 bottom-2">
                  <button
                    className="text-sm text-primary transition-colors"
                    onClick={() => setIsModalOpen(true)}
                  >
                    View
                  </button>
                  <button
                    className="text-sm text-primary transition-colors"
                    onClick={handleDownload}
                  >
                    Download
                  </button>
                  {!state && !getUnreportedData && (
                    <button
                      className="text-sm text-primary transition-colors"
                      onClick={() =>
                        setFormData((prevData) => ({
                          ...prevData,
                          selectedFile: null,
                        }))
                      }
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>

              <PreviewModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                file={formData.selectedFile}
                  editUpdate={state||getUnreportedData}
                onDownload={handleDownload}
              />
            </>
          )}
          {errors.selectedFile && !formData.selectedFile && (
            <p className="mt-2 text-sm text-red-500">
              Please attach a file to proceed further
            </p>
          )}
        </div>
        <div className="lg:h-[60vh] lg:border-[0.1px] lg:border-line-color"></div>
        <div className="w-full lg:w-2/5 lg:pl-4">
          <div className="space-y-4 flex flex-col items-start">
            <div className="w-full">
              <div className="flex flex-col items-start">
                <label className="block text-sm font-medium mb-2 w-2/3">
                  Expense Type
                </label>

                <div
                  ref={dropdownRef}
                  className={`w-11/12 ${
                    errors.expenseType ? 'border border-red-500 rounded-md' : ''
                  }`}
                >
                  <Dropdown
                    label="Expense Type"
                    name="expense_Type"
                    value={formData.expenseType}
                    options={allExpenseType}
                    attributeValue="expense_Type"
                    attributeName="expense_Type"
                    onChange={handleExpenseTypeChange}
                    isDisable={false}
                    disabledValue="Expense Type"
                    isOpen={currentDropdown === 'expense_Type'}
                    onDropdownToggle={handleDropdownToggle}
                    dropdownWidth="w-full"
                    className="rounded-md text-baseMd text-black focus:outline-none focus:ring-2 focus:ring-[#A15CA4]"
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="flex flex-col items-start">
                <label className="block text-sm font-medium mb-2 w-2/3">
                  Invoice Details
                </label>
                <div className="w-11/12 space-y-4">
                  <input
                    type="text"
                    value={formData.invoiceNumber}
                    onChange={(e) =>
                      handleChange('invoiceNumber', e.target.value)
                    }
                    placeholder="Invoice Number"
                    place
                    className={`w-full p-2 border text-baseMd ${
                      errors.invoiceNumber
                        ? 'border-red-500'
                        : 'border-gray-300'
                    } rounded-md focus:outline-none focus:ring-2 focus:ring-[#A15CA4]`}
                  />

                  {/* Invoice Date  add border color red if field is empty **/}

                  <div
                    className={`rounded-md relative  ${
                      errors.invoiceDate ? 'border border-red-500' : ''
                    }`}
                  >
                    <DatePicker
                      selected={startDate}
                      onChange={handleDateChange}
                      placeholderText="Select Date"
                      dateFormat="dd/MM/yyyy"
                      className={`h-[2.5rem] w-[21.5rem]  text-baseMd z-50 border  pl-2  font-medium placeholder:text-[14px] placeholder:text-[#a09896] placeholder:font-normal

                        ${
                          errors.invoiceDate && startDate
                            ? 'border-red-500'
                            : 'border-gray-300'
                        }
                         rounded-md focus:outline-none focus:ring-2 focus:ring-[#A15CA4]`}
                      onChangeRaw={(e) => e.preventDefault()}
                      ref={datePickerRef}
                    />
                    <img
                      onClick={handleIconClick}
                      src={calenderGrayUploadBillIcon}
                      alt="calender"
                      className="absolute top-[50%] right-8 transform -translate-y-1/2 cursor-pointer"
                    />
                  </div>

                  <input
                    type="text"
                    value={formData.invoiceAmount}
                    onChange={(e) => {
                      const value = e.target.value;
                if (/^\d*\.?\d*$/.test(value)) {
                        handleChange('invoiceAmount', e.target.value);
                      }
                    }}
                    placeholder="Invoice Amount"
                    className={`w-full p-2 border text-baseMd ${
                      errors.invoiceAmount
                        ? 'border-red-500'
                        : 'border-gray-300'
                    } rounded-md focus:outline-none focus:ring-2 focus:ring-[#A15CA4]`}
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="flex flex-col items-start">
                <label className="block text-sm font-medium mb-2 w-2/3">
                  Total Amount
                </label>
                <div className="flex w-11/12 bg-gray-100">
                  <span className="inline-flex items-center px-3 border border-r-0 border-gray-300 bg-gray-100 text-gray-500 rounded-l-md">
                    ₹
                  </span>
                  <input
                    type="number"
                    value={formData.invoiceAmount}
                    onChange={(e) =>
                      handleChange('totalAmount', e.target.value)
                    }
                    placeholder="Total Amount"
                    className={`flex-1 p-2 border
                       rounded-r-md `}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider-y h-[0.5px] w-[98%] bg-line-color mb-3"></div>

      <div className="flex justify-end gap-4 pr-7">
        {state || getUnreportedData ? (
          <div className="flex gap-4">
            <button
              className="px-8 py-2 border bg-white border-primary text-primary rounded-3xl  transition-colors"
              onClick={handlerCancel}
            >
              Cancel
            </button>
            <button
              className="px-8 py-2 border bg-primary border-primary text-white rounded-3xl  transition-colors"
              onClick={handleUpdate}
            >
              Update
            </button>
          </div>
        ) : (
          <div className="flex gap-4">
            <button
              className="px-8 py-2 border border-primary text-primary rounded-3xl hover:bg-gray-50 transition-colors"
              onClick={() => handlerNavigate('/home')}
            >
              Cancel
            </button>
            <button
              className="px-8 py-2 bg-primary text-white rounded-3xl hover:bg-primary transition-colors"
              onClick={handleAttach}
            >
              Attach
            </button>
          </div>
        )}
      </div>
      {showSuccessModal && (
        <PopupModal
          time={2000}
          isOpen={showSuccessModal}
          message={showUpdateSuccessModal || errorMessage}
          type={apiError ? 'Fail' : 'success'}
          onClose={handleSuccessModalClose}
        />
      )}
    </div>
  );
};

export default UploadBills;
