import React from 'react';

const ConfirmationPopup = ({
  show,
  message,
  onDenied,
  onProceed,
  // isUnreported = false,
}) => {
  return show ? (
    <div className="absolute w-full h-full bg-disable z-30 flex justify-center items-center left-0 top-0">
      <div
        className={`p-5 rounded-md bg-white w-[90%]
         flex flex-col justify-center items-center gap-5 py-5  max-w-[25rem] `}
      >
        <p className="text-center">{message}</p>
        <div className="flex justify-center gap-5 w-full">
          <button
            className=" text-primary border border-primary bg-white  p-3 py-2 w-1/4 rounded-full"
            onClick={onDenied}
          >
            No
          </button>
          <button
            className="bg-primary text-white  p-3 py-2 w-1/4 rounded-full"
            onClick={onProceed}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default ConfirmationPopup;
