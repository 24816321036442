import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const Dropdown = ({
  label = 'Select an option',
  options = [],
  name,
  attributeName = '',
  onChange,
  dropdownHeight = 'smAndMd:h-12  lg:h-10',
  dropdownWidth = 'w-full sm:w-52 md:w-72 lg:w-52',
  attributeValue = '',
  isDisable = false,
  disabledValue = '',
  value = '',
  isOpen,
  onDropdownToggle,
}) => {
  const [selected, setSelected] = useState(value ||label || '');
  const memoizedOptions = useMemo(() => options || [], [options]);

  useEffect(() => {
    if (value === '' || value === undefined || value === null) {
      setSelected(label);
    }
  }, [value, label]);

  useEffect(() => {
    if (isDisable && disabledValue && selected !== disabledValue) {
      setSelected(disabledValue);
    }
  }, [isDisable, disabledValue, selected]);

  const toggleDropdown = () => {
    if (!isDisable) {
      onDropdownToggle(name);
    }
  };

  const handleOptionClick =useCallback((option) => {
    if (!isDisable) {
      setSelected(option[attributeName] || option);
      onChange({ [name]: option[attributeValue] || option });
      onDropdownToggle(null); // Close dropdown after selection
    }
  },    [attributeName, attributeValue, name, onChange, isDisable]
);

  return (
    <div className="relative w-full">
      <button
        type="button"
        className={`flex items-center justify-between border rounded
          ${dropdownWidth} ${dropdownHeight} ${isDisable ? 'bg-gray-100' : ''}
          px-2 sm:px-3 md:px-4
          text-sm sm:text-base
          transition-all duration-200`}
        onClick={toggleDropdown}
        disabled={isDisable}
      >
        <span className={`truncate lg:text-baseMd ${selected === label ? 'text-gray-400 lg:text-[14px]' : 'text-black'}`}>
          {selected}
        </span>
        <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} className="ml-2" />
      </button>
      {isOpen && (
        <div className={`absolute bg-white border rounded shadow-md mt-1
          ${dropdownWidth}
          max-h-[200px] sm:max-h-[250px] md:max-h-[350px]
          overflow-y-auto z-10`}>
          {memoizedOptions.map((option, index) => (
            <button
              key={index}
              type="button"
              className="block w-full text-left
                px-2 sm:px-3 md:px-4
                py-1 sm:py-2
                text-sm sm:text-base
                hover:bg-gray-200 transition-colors"
              onClick={() => handleOptionClick(option)}
            >
              <span className="truncate block">
                {option[attributeName]}
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;