import React, { useEffect, useRef, useState } from 'react';
import { Camera } from 'react-camera-pro';
// import campturedImageIcon from '../../../../../public/assets/capturedImageIcon.png';
import rotateIcon from '../../../../public/assets/images/rotateIcon.png';
import campturedImageIcon from '../../../../public/assets/images/capturedImageIcon.png';
import fileUploadIcon from '../../../../public/assets/images/fileUploadIcon.png';
import xIcon from '../../../../public/assets/images/xIcon.png';
import flashIcon from '../../../../public/assets/images/flashIcon.png';
import { useNavigate } from 'react-router-dom';
import ReactCrop from 'react-image-crop';
import Button from '../../../components/button/Button';
import { updateUserProfileImage } from '../../../redux/slice/userCameraProfile';
import { useDispatch } from 'react-redux';

function canvasPreview(image, canvas, crop, scale = 1) {
  const ctx = canvas.getContext('2d');
  if (!ctx) throw new Error('No 2d context');

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  ctx.save();
  ctx.translate(-cropX, -cropY);
  ctx.translate(image.naturalWidth / 2, image.naturalHeight / 2);
  ctx.scale(scale, scale);
  ctx.translate(-image.naturalWidth / 2, -image.naturalHeight / 2);
  ctx.drawImage(image, 0, 0);
  ctx.restore();
}

function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const timeout = setTimeout(fn, waitTime);
    return () => clearTimeout(timeout);
  }, deps);
}

const ProfileCamera = ({ setShowCamera, setProfilePic }) => {
  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [hasCameraPermission, setHasCameraPermission] = useState(true);
  const [isFlashOn, setIsFlashOn] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cropValue, setCropValue] = useState({
    height: window.innerWidth - 100,
    unit: 'px',
    width: window.innerWidth - 100,
    x: 50,
    y: (window.innerHeight - (window.innerWidth - 100)) / 2,
  });
  const previewCanvasRef = useRef(null);
  const imageRef = useRef(null);
  const [crop, setCrop] = useState({
    height: window.innerWidth - 100,
    unit: 'px',
    width: window.innerWidth - 100,
    x: 50,
    y: (window.innerHeight - (window.innerWidth - 100)) / 2,
  });

  const handleTakePhoto = async () => {
    if (camera.current) {
      setImage(camera.current.takePhoto());
      setShowImage(true);
    }
  };

  async function handlerCropImage() {
    try {
      const image = imageRef.current;
      const previewCanvas = previewCanvasRef.current;
      if (!image || !previewCanvas || !cropValue) {
        throw new Error('Crop canvas does not exist');
      }

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const offscreen = new OffscreenCanvas(
        cropValue.width * scaleX,
        cropValue.height * scaleY
      );
      const ctx = offscreen.getContext('2d');
      if (!ctx) throw new Error('No 2d context');

      ctx.drawImage(
        previewCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height,
        0,
        0,
        offscreen.width,
        offscreen.height
      );

      const blob = await offscreen.convertToBlob({ type: 'image/png' });

      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(updateUserProfileImage(reader.result));
        setShowCamera();
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error in handlerCropImage:', error);
    }
  }

  useDebounceEffect(
    () => {
      if (
        cropValue?.width &&
        cropValue?.height &&
        imageRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imageRef.current, previewCanvasRef.current, cropValue, 1);
      }
    },
    100,
    [cropValue, image]
  );

  return (
    <div>
      {showImage ? (
        <div className="absolute w-screen h-screen flex flex-col justify-between items-center">
          <div className="relative bg-red-700 h-[30px] w-screen h-screen">
            <button
              onClick={setShowCamera}
              className="absolute top-0 left-0 z-50"
            >
              <img src={xIcon} alt="Close" className="h-[25px] w-[25px]" />
            </button>
            <div className="bg-white absolute p-4 bottom-0 z-50 shadow-top1 border-t-[1px] w-full flex justify-around gap-3 items-center h-[8rem]">
              {/* <button className='grow border-[1px] border-primary text-primary p-2'>Cancel</button>
              <button className='grow bg-primary text-white border-primary p-2' onClick={handlerCropImage}>Save</button> */}
              <Button
                label="Cancel"
                // onClick={handlerCropImage}
                btBgColor="white"
                btTextColor="text-primary"
                onClick={setShowCamera}
              />
              <Button
                label="Save"
                onClick={handlerCropImage}
                btBgColor="bg-primary"
                btTextColor="text-white"
              />
            </div>
            <div className="">
              <ReactCrop
                crop={crop}
                onChange={(c, p) => {
                  setCrop(c);
                }}
                onComplete={(c) => {
                  setCropValue(c);
                }}
                aspect={1}
              >
                <img
                  src={image}
                  alt="capturedUserProfileImage"
                  className="w-screen h-screen"
                  ref={imageRef}
                />
              </ReactCrop>
              <canvas
                ref={previewCanvasRef}
                style={{
                  visibility: 'hidden',
                }}
              ></canvas>
            </div>
          </div>
          <div>
            <button
              onClick={() => {
                handleTakePhoto();
              }}
            >
              <img
                src={campturedImageIcon}
                alt="Take Photo"
                className="w-[100px]"
              />
            </button>
          </div>
        </div>
      ) : (
        <>
          <Camera ref={camera} />
          <div className="absolute w-screen h-screen flex flex-col justify-between items-center">
            <div className="h-[30px] w-screen h-screen">
              <button onClick={setShowCamera}>
                <img src={xIcon} alt="Close" className="h-[25px] w-[25px]" />
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  setImage(camera.current.takePhoto()), handleTakePhoto();
                }}
              >
                <img
                  src={campturedImageIcon}
                  alt="Take Photo"
                  className="w-[100px]"
                />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default ProfileCamera;
