import { useLocation } from 'react-router-dom';

const useNavigation = (isLoggedIn) => {
  const location = useLocation();
  const { pathname } = location;

  // Hide NavBar and DrawerButton on login, root, and logout routes
  const showNavBar = pathname !== '/login' && pathname !== '/' && pathname !== '/logout';
  const showDrawerButton = isLoggedIn && pathname === '/home';

  return { showNavBar, showDrawerButton };
};

export default useNavigation;
