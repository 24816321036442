import React from 'react';

const PreviewModal = ({ isOpen, onClose, file, onDownload,editUpdate }) => {
  if (!isOpen || !file) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-3/5 max-w-md relative">
        <div className="flex justify-between items-center border-b p-4">
          <h3 className="text-[20px]  text-[#514D4C]">File Preview</h3>
          <button
            onClick={onClose}
            className="text-primary transition-colors text-2xl"
          >
            x
          </button>
        </div>
        <div className="p-4">
          {file.preview || editUpdate ? (
            file?.name?.endsWith('.pdf') ? (
              <iframe
                src={file?.preview}
                title="PDF Preview"
                className="w-full h-64 border"
              ></iframe>
            ) : (
              <img
                src={editUpdate ?'data:image/png;base64,'+file:file?.preview}
                alt="File Preview"
                className="w-full h-64 object-contain"
              />
            )
          ) : (
            <p className="text-gray-500">Preview not available for this file.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewModal;
