import React from 'react';

const NotificationCardContainer = ({
  text,
  icon,
  date,
  onNotificationClick,
  id,
}) => {
  return (
    <>
      <div
        className="flex items-center gap-5 lg:gap-2 p-4 lg:p-2  m-2 rounded"
        onClick={() => {
          onNotificationClick(id, text);
        }}
      >
        <div className="p-3 rounded-full border border-secondary-200">
          <img src={icon} alt="expense-icon" width={'20px'} height={'20px'} />
        </div>

        <div className="flex  gap-2 text-start">
          <div className="font-bold lg:text-[15px]">{text}</div>
          <div className="flex flex-col justify-center">
            <p className="bg-primary w-4 h-4 rounded-full"></p>
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-gray-300 lg:hidden"></div>
    </>
  );
};

export default NotificationCardContainer;
