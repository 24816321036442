import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { routersConfig } from './router/routersConfig';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import SplashScreen from './components/splashScreen/SplashScreen';
import NavBar from './components/navbar/Navbar';
import OfflineHandler from './components/offline/OfflineHandler.jsx';
import useNetworkStatus from './hook/useNetworkStatus'; // Import custom hook


const App = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isOnline = useNetworkStatus();
  const location = useLocation();
  const navigate = useNavigate();
    const checkTokenAndNavigate = () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsLoggedIn(false);
        navigate('/login');
      } else {
        setIsLoggedIn(true);
      }
    };
    useEffect(() => {
    checkTokenAndNavigate();
  }, [location.pathname]);

  return isOnline ? (
    <div className="flex h-screen">
    {/* Navbar */}
    <NavBar
      isLoggedIn={isLoggedIn}
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={() => setIsDrawerOpen((prev) => !prev)}
    />

    {/* Main Content */}
    <main className={`flex-1 overflow-y-auto ${isLoggedIn && location.pathname === '/home' ? 'pt-14' : ''}`}>
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />
        {routersConfig?.map((route) => (
          <Route key={route?.path} path={route?.path} element={route?.element} />
        ))}
      </Routes>
    </main>
  </div>
  ) : (
<OfflineHandler />
  );
};

export default App;