import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import NotificationCardContainer from './NotificationCardContainer';
import expenseIcon from '../../assets/icons/expense-icon.png';
import callplanIcon from '../../assets/icons/callplan-icon.png';
import phoneIcon from '../../assets/icons/phone-icon.png';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletenotificationDetails,
  notificationDetails,
  resetDeleteModal,
} from '../../redux/slice/notification';
import Spinner from '../../components/Spinner';
import ErrorsPopup from '../sfc/ErrorsPopup';

const Notification = ({ isDesktop = false }) => {
  const dispatch = useDispatch();
  const {
    data: notification,
    isLoading,
    isNotificationDelete,
  } = useSelector((state) => state.notificationInfo);

  const [showRemarks, setShowRemarks] = useState(null);
  const [notificationMsg, setNotificationMsg] = useState({
    notifyMsg: '',
    notifyId: 0,
  });

  useEffect(() => {
    dispatch(notificationDetails());
  }, [isNotificationDelete]);

  const onNotificationClick = (id, text) => {
    dispatch(resetDeleteModal());
    setNotificationMsg((prev) => ({ ...prev, notifyMsg: text, notifyId: id }));
    setShowRemarks(true);
  };

  return (
    <div>
      {isLoading ? (
        <Spinner transparent={isDesktop ? true : false} />
      ) : (
        <React.Fragment>
          <ErrorsPopup
            show={showRemarks}
            onClose={() => {
              setShowRemarks(false),
                dispatch(deletenotificationDetails(notificationMsg?.notifyId));
            }}
            message={notificationMsg?.notifyMsg}
          />
          <div className="flex m-8 justify-between lg:justify-center lg:m-5">
            <button
              onClick={() => {
                window.history.back();
              }}
              className="lg:hidden"
            >
              <FontAwesomeIcon
                icon={faAngleLeft}
                className="text-primary text-xl"
              />
            </button>
            <p className="text-primary font-semibold text-xl lg:text-[#123C69] lg:text-[18px]">
              Notification
            </p>
            <div></div>
          </div>

          <div className="m-5 h-[1px] bg-[#B3B3B3] smAndMd:hidden"></div>

          <div className="px-5 flex flex-col">
            {notification?.notificationMessage?.length > 0 ? (
              notification?.notificationMessage?.map((val, index) => {
                return (
                  <div className="hover:bg-[#D56AC726]">
                    <NotificationCardContainer
                      key={index}
                      text={val?.notificationMessage}
                      icon={
                        val?.notificationMessage
                          .toLowerCase()
                          .startsWith('expense')
                          ? expenseIcon
                          : phoneIcon
                      }
                      // date={'23/01/2024'}
                      onNotificationClick={onNotificationClick}
                      id={val?.notificationId}
                    />
                  </div>
                );
              })
            ) : (
              <div className="flex justify-center items-center text-primary">
                No Notifications
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Notification;
