import React, { useEffect, useState } from 'react';
import PopupModal from '../../components/modal/PopupModal';
import { logout } from '../../redux/slice/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    dispatch(logout());
    setShowSuccessModal(true);

    const timer = setTimeout(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('userData');
      console.log('Logged out successfully');
      navigate('/');
    }, 2000);

    return () => clearTimeout(timer);
  }, [dispatch, navigate]);

  return (
    <div className="flex items-center justify-center mt-12 flex-col">
      <PopupModal
        time={2000} // 2 seconds
        isOpen={showSuccessModal}
        message="Logged out successfully"
        type="success"
        onClose={() => setShowSuccessModal(false)}
      />
    </div>
  );
};

export default Logout;
