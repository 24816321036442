import React, { useRef, useState, useEffect } from 'react';
import { Camera } from 'react-camera-pro';
import { useDispatch } from 'react-redux';
import { setCaptureImage } from '../../redux/slice/cameraSlice';
import { useNavigate } from 'react-router-dom';

// images import
import campturedImageIcon from '/assets/images/capturedImageIcon.png';
import rotateIcon from '/assets/images/rotateIcon.png';
import fileUploadIcon from '/assets/images/fileUploadIcon.png';
import xIcon from '/assets/images/xIcon.png';
import flashIcon from '/assets/images/flashIcon.png';

const CameraOption = () => {
  const [isFlashOn, setIsFlashOn] = useState(false);
  const [hasCameraPermission, setHasCameraPermission] = useState(true);

  const navigate = useNavigate();
  const camera = useRef(null);
  const dispatch = useDispatch();

  const handleTakePhoto = async () => {
    if (camera.current) {
      try {
        const photo = await camera.current.takePhoto();
        dispatch(setCaptureImage(photo));
        handlerNavigate('/capture-image');
      } catch (error) {
        console.error('Error taking photo:', error);
      }
    }
  };
  const checkPermission = async () => {
    const result = await navigator.permissions.query({ name: 'camera' });
    if (result.state === 'granted') {
      setHasCameraPermission(true);
    } else if (result.state === 'denied') {
      console.log('Camera permission denied', result);
      setHasCameraPermission(false);
    }
  };

  useEffect(() => {
    checkPermission();
  }, []);

  const handleSwitchCamera = async () => {
    if (camera.current) {
      try {
        const switchCamera = await camera.current.switchCamera();
        console.log('switchCamera', switchCamera);
      } catch (error) {
        console.error('Error switching camera:', error);
      }
    }
  };

  const handleTorchLight = async () => {
    if (camera.current) {
      try {
        const toggleTorch = await camera.current.toggleTorch();
        console.log('toggleTorch', toggleTorch);
        setIsFlashOn(!isFlashOn);
      } catch (error) {
        console.error('Error toggling torch:', error);
      }
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(setCaptureImage(reader.result));
      };
      reader.readAsDataURL(file);
      navigate('/capture-image');
    }
  };

  const handlerNavigate = (path) => navigate(path);

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center">
      <Camera
        ref={camera}
        facingMode="environment"
        aspectRatio="cover"
        className="w-full h-full"
        errorMessages={{
          permissionDenied:
            'Permission denied. Please refresh and give camera permission.',
          switchCamera:
            'It is not possible to switch camera to different one because there is only one video device accessible.',
          canvas: 'Canvas is not supported.',
        }}
        videoReadyCallback={() => console.log('Video feed ready.')}
      />
      {hasCameraPermission ? (
        <div className="w-full h-full relative">
        <div className="absolute top-0 w-full bg-primary-black h-20 flex items-center justify-between px-4">
          <button onClick={() => handlerNavigate('/home')} className="flex items-center">
            <img src={xIcon} alt="Close" className="h-[25px] w-[25px]" />
          </button>

          <button
            className={`text-white font-bold rounded-full flex items-center ${isFlashOn ? 'bg-gray-400' : ''}`}
            onClick={handleTorchLight}
          >
            <img src={flashIcon} alt="Flash" className="h-[25px] w-[25px]" />
          </button>
        </div>

        {/* Bottom Control Bar */}
        <div className="absolute bottom-0 w-full bg-primary-black py-4 flex justify-evenly items-center">
          <label htmlFor="dropzone-file" className="cursor-pointer flex flex-col items-center justify-center h-16 w-14">
            <img src={fileUploadIcon} alt="Upload" className="h-[35px] w-[35px]" />
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={handleFileChange}
            />
          </label>

          <button onClick={handleTakePhoto}>
            <img
              src={campturedImageIcon}
              alt="Take Photo"
              className="h-[100px] w-[100px]"
            />
          </button>

          <button
            className="text-white font-bold rounded-full h-16 w-14 flex items-center justify-center"
            onClick={handleSwitchCamera}
          >
            <img src={rotateIcon} alt="Switch Camera" className="h-[35px] w-[35px]" />
          </button>
        </div>
      </div>
      ) : (
        <div className="w-full h-full flex flex-col items-center z-20 justify-center">
          <p className="pb-4 p-4">No camera device accessible. Please connect your camera or try a different browser.</p>
          <button
            className="text-gray-400 font-bold py-2 px-4 rounded-full border-2"
            onClick={() => handlerNavigate('/home')}
          >
            Go Back
          </button>
        </div>
      )}
    </div>
  );
};

export default CameraOption;
