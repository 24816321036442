import { faAngleLeft, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearUserState,
  updateUserDetails,
  userDetails,
  userProfileDeleted,
  userProfileUpdated,
} from '../../redux/slice/userProfile';
import Loader from '../../components/modal/Loader';
import defaultUserIcon from '../../../public/assets/images/defaultUserIcon.png';
import Input from './Input';
import CameraOptionPopup from './CameraOptionPopup';
import SuccessfullPopup from '../sfc/SuccessfullPopup';
import editIcon from '../../../public/assets/images/edit.png';
import ProfileCamera from './ProfileCamera/index.jsx';
import { getUserProfileImage } from '../../redux/slice/userCameraProfile';

const validateUserForm = ({ address, phoneNumber, pincode, emailId }) => {
  const error = {};
  const gmailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
  if (!address) error.address = 'Please Select';
  if (!phoneNumber) error.phoneNumber = 'Please Select';
  if (!pincode) error.pincode = 'Please Select';
  if (!emailId) error.emailId = 'Please Select';
  if (!gmailRegex.test(emailId)) error.emailId = 'Please enter valid gmail.';
  return [
    !!address &&
      !!phoneNumber &&
      !!pincode &&
      !!emailId &&
      !!gmailRegex.test(emailId),
    error,
  ];
};

const User = () => {
  const [onChange, setOnChange] = useState(true);
  const dispatch = useDispatch();
  const { isLoading: loading, data: userProfileDetails } = useSelector(
    (state) => state.userDetails
  );

  const { data: image } = useSelector((state) => state.userProfileImage);

  const { isUserUpdate } = useSelector((state) => state.userDetails);
  const { isUserDeleted } = useSelector((state) => state.userDetails);

  const [userUpdatedDetails, setUserUpdatedDetails] = useState({});
  const [userValidateError, setUserValidateError] = useState({});
  const [showModal, setShowModal] = useState(true);
  const [showCamera, setShowCamera] = useState(false);
  const [profilePic, setProfilePic] = useState(defaultUserIcon);

  useEffect(() => {
    if (userProfileDetails?.empCode) {
      setUserUpdatedDetails({
        ...userProfileDetails,
      });
    }
  }, [userProfileDetails]);

  useEffect(() => {
    dispatch(userDetails());
    dispatch(getUserProfileImage());
  }, [isUserDeleted, image?.imageBase64]);

  const onUpdateHandler = () => {
    const [validate, errors] = validateUserForm(userUpdatedDetails);
    if (!validate) {
      setUserValidateError(errors);
      return;
    }

    if (!onChange) {
      dispatch(updateUserDetails(userUpdatedDetails));
    }
  };

  const onChangeHandler = ({ target: { value, name } }) => {
    setOnChange(false);
    setUserUpdatedDetails({ ...userUpdatedDetails, [name]: value });
    setUserValidateError({ ...userValidateError, [name]: '' });
  };

  return (
    <div className="h-lvh lg:relative lg:pt-[57px]">
      {loading ? (
        <Loader />
      ) : isUserUpdate || isUserDeleted ? (
        <SuccessfullPopup
          displayText={isUserDeleted ? 'Deleted' : 'Updated'}
          onClose={() => {
            dispatch(userProfileUpdated(false)),
              setOnChange(true),
              dispatch(clearUserState());
          }}
        />
      ) : showCamera ? (
        <ProfileCamera
          setShowCamera={() => {
            setShowCamera(false);
            setShowModal(true);
          }}
          setProfilePic={setProfilePic}
        />
      ) : (
        <div
          className="w-full h-full flex flex-col relative"
          // onClick={() => setShowModal(true)}
        >
          {!showModal ? (
            <div
              className="w-full h-full absolute left-0 top-0 z-[10]"
              onClick={() => setShowModal(true)}
            ></div>
          ) : null}
          <div className="flex flex-col items-center lg:gap-2">
            <div className="lg:px-3 w-full h-full lg:mb-5">
              <div className="w-full h-full flex px-6 py-5  justify-between lg:justify-start gap-4 items-center lg:border-b-2 lg:border-line-color lg:gap-2 lg:px-0">
                <div>
                  <Link to="/home" className="">
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      color="#A04D95"
                      size="lg"
                    />
                  </Link>
                </div>
                <p className="text-primary font-semibold text-lg text-center">
                  <span className="smAndMd:hidden">My</span> Profile
                </p>
                <div></div>
              </div>
              {/* text-primary text-[20px] font-semibold px-4 pt-[15px] hidden lg:flex */}
            </div>
            <div className="relative">
              <img
                src={image?.imageBase64 || profilePic}
                alt=""
                // width={'100px'}
                // height={'100px'}
                className="rounded-full w-[100px] h-[100px] lg:w-[150px] lg:h-[150px]"
              />
              <span
                className="absolute left-[41px] lg:left-[65px] bottom-[5px]"
                onClick={(e) => {
                  e.stopPropagation(), setShowModal((prev) => !prev);
                  dispatch(userProfileDeleted(false));
                }}
              >
                <img src={editIcon} alt="edit" width={'18px'} height={'18px'} />
              </span>
              {!showModal ? (
                <CameraOptionPopup
                  onCloseModal={setShowModal}
                  setShowCamera={setShowCamera}
                />
              ) : null}
            </div>
          </div>

          <div className="w-full smAndMd:h-full px-6 flex flex-col gap-5 mt-8 lg:grid lg:grid-cols-2 lg:px-16">
            <Input
              label={'Full Name'}
              name={'employeeName'}
              onChange={onChangeHandler}
              value={userUpdatedDetails?.employeeName}
              disabled
            />
            <Input
              label={'Emp Code'}
              name={'empCode'}
              onChange={onChangeHandler}
              value={userUpdatedDetails?.empCode}
              disabled
            />
            <Input
              label={'Designation'}
              name={'designation'}
              onChange={onChangeHandler}
              value={userUpdatedDetails?.designation}
              disabled
            />
            <Input
              label={'Address'}
              name={'address'}
              onChange={onChangeHandler}
              value={userUpdatedDetails?.address}
              error={userValidateError?.address}
            />
            <div className="flex gap-2 lg:hidden">
              <Input
                label={'City'}
                name={'city'}
                onChange={onChangeHandler}
                value={userUpdatedDetails?.city}
                disabled
              />

              <Input
                label={'State'}
                name={'state'}
                onChange={onChangeHandler}
                value={userUpdatedDetails?.state}
                disabled
              />
            </div>
            <div className="smAndMd:hidden">
              <Input
                label={'City'}
                name={'city'}
                onChange={onChangeHandler}
                value={userUpdatedDetails?.city}
                disabled
              />
            </div>
            <div className="smAndMd:hidden">
              <Input
                label={'State'}
                name={'state'}
                onChange={onChangeHandler}
                value={userUpdatedDetails?.state}
                disabled
              />
            </div>
            <Input
              label={'Pin'}
              name={'pincode'}
              onChange={onChangeHandler}
              value={userUpdatedDetails?.pincode}
              error={userValidateError?.pincode}
            />
            <Input
              label={'Phn no'}
              name={'phoneNumber'}
              onChange={onChangeHandler}
              value={userUpdatedDetails?.phoneNumber}
              error={userValidateError?.phoneNumber}
            />
            <Input
              label={'Email Id'}
              name={'emailId'}
              onChange={onChangeHandler}
              value={userUpdatedDetails?.emailId}
              error={userValidateError?.emailId}
            />
          </div>

          <div className="w-full flex justify-end items-center bg-white p-5 shadow-top py-[32px] lg:shadow-none lg:px-16">
            <button
              className={`w-full lg:w-[135px] ${
                onChange ? 'bg-lightGray' : 'bg-primary'
              } text-white rounded-full p-3 font-bold lg:cursor-pointer`}
              onClick={onUpdateHandler}
            >
              Update
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default User;
