import React from 'react';
import Input from '../../components/input';
import Spinner from '../../components/Spinner';

const SfcFormTwo = ({
  onChangeHandler,
  formValues,
  disableOneway,
  errors,
  loading,
}) => {
  return (
    <div className="lg:p-2 lg:w-[90%]">
      {loading && <Spinner text="Loading" transparent />}
      <div className="text-secondary-400 text-fieldset lg:hidden">Distance</div>
      <div className="w-1/1 h-[0.5px] bg-line-color mt-4 mb-7 lg:hidden"></div>
      <div className="flex gap-6">
        <Input
          label="One-way"
          name="oneWayDistance"
          value={formValues?.oneWayDistance}
          onChange={onChangeHandler}
          disabled={true}
          labelClassName="top-[-18px] left-[1px]"
          className="top-[-18px] left-[1px]"
          containerClassName="grow"
          // disabled={disableOneway}
          // error={errors.oneWayDistance}
        />
        <Input
          label="Two-way"
          value={formValues?.twoWayDistance}
          disabled
          containerClassName="grow"
        />
      </div>
      <div className="mt-8">
        <div className="text-secondary-400 text-fieldset lg:hidden">
          Fare & Allowance
        </div>
        <div className="w-1/1 h-[0.5px] bg-line-color mt-4 mb-7"></div>
        <div className="grid grid-cols-2 gap-6">
          <Input
            type="text"
            label="One-way Fare"
            value={formValues?.oneWayFare}
            disabled
          />
          <Input
            type="text"
            label="Two-way Fare"
            value={formValues?.twoWayFare}
            disabled
          />
          <Input
            type="text"
            label="Allowance"
            value={formValues?.allowance}
            disabled
          />
          <Input type="text" label="Total" value={formValues?.total} disabled />
        </div>
      </div>
    </div>
  );
};

export default SfcFormTwo;
