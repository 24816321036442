import React, { useState } from 'react';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';

//varient values should be  animated , vertical, and if placeholder is passed it is plane
const Select = ({
  varient = 'animated',
  options = ['option 1', 'option 2', 'option 3', 'option 4'],
  name,
  value,
  onChange,
  label,
  placeholder,
  disabled = false,
  keyProvider,
  valueAttri,
  labelAttri,
  error,
  ...restProps
}) => {
  const [isFocused, setIsFocused] = useState(false);
  label = label || 'Enter ' + name;

  const onSelectHandler = (value, event) => {
    event.stopPropagation();
    setIsFocused(false);
    onChange &&
      onChange({
        target: { name, value: valueAttri ? value[valueAttri] : value },
      });
  };

  return (
    <div>
      <div
        className={`custom-dropdown relative ${
          varient === 'vertical' ? 'vertical-selectbox' : ''
        } leading-7 border mx-2 rounded mt-7 lg:mt-3 ${
          isFocused || value ? 'focused' : ''
        }
        ${
          isFocused
            ? 'border-primary'
            : disabled
            ? 'border-disable'
            : !!error
            ? 'border-red-700 form-error'
            : 'border-secondary-200'
        }
      `}
        onClick={() => setIsFocused((prev) => !prev)}
      >
        <button
          disabled={disabled}
          type="text"
          className={`flex w-full h-12 p-3 items-center justify-between outline-none`}
          onBlur={() => {
            setTimeout(() => {
              setIsFocused(false);
            }, 300);
          }}
          {...restProps}
        >
          {!placeholder ? (
            <p
              className={`absolute selectbox-label font-medium ${
                disabled
                  ? 'text-disable-200'
                  : isFocused
                  ? 'text-primary'
                  : !!error
                  ? 'text-red-600'
                  : value
                  ? 'text-primary'
                  : 'text-secondary'
              }`}
            >
              {label}
            </p>
          ) : null}
          <span
            className={`grow text-start ${
              !value && placeholder ? 'text-secondary-200' : ''
            } ${disabled ? 'text-disable-200' : ''}`}
          >
            {value
              ? valueAttri
                ? options.find((option) => option[valueAttri] === value)?.[
                    labelAttri
                  ]
                : value
              : placeholder}
          </span>{' '}
          <FontAwesomeIcon
            style={{ transform: isFocused ? 'rotate(180deg)' : null }}
            icon={faCaretDown}
            className={`${disabled ? 'text-disable-200' : ''}`}
          />
        </button>

        {isFocused && (
          <ul className="absolute bg-white z-[1000000] w-full border-2 border-disabled rounded-md py-2 mt-1 max-h-[200px] overflow-auto">
            {options.length
              ? options.map((option) => {
                  return (
                    <li
                      className={`p-2 px-4 cursor-pointer hover:bg-disable ${
                        value &&
                        (value === option || value === option[valueAttri])
                          ? 'bg-cyan-50'
                          : ''
                      }`}
                      onClick={onSelectHandler.bind(this, option)}
                      key={
                        keyProvider
                          ? keyProvider(option)
                          : valueAttri
                          ? option[valueAttri]
                          : option
                      }
                    >
                      {option[labelAttri] || option}
                    </li>
                  );
                })
              : null}
          </ul>
        )}
      </div>
      {error && !disabled ? (
        <p className="text-red-600 mx-2 pl-2">{error}</p>
      ) : null}
    </div>
  );
};
export default Select;
