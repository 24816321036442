import React from "react";
import useNetworkStatus from "../../hook/useNetworkStatus"; // Import custom hook
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi } from "@fortawesome/free-solid-svg-icons";


const OfflineHandler = () => {
  const isOnline = useNetworkStatus();
  if (isOnline) return null;
  
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-100 text-center px-4">
      <FontAwesomeIcon icon={faWifi} className="text-primary text-6xl rotate-45 " height={"50px"} width={"50px"} />
      <p className="text-lg font-semibold text-primary mt-4 lg:text-baselg smAndMd:text-baseMd">You are offline</p>
      <p className="mt-1 text-gray-600 smAndMd:text-baseSm">Please check your internet connection.</p>
    </div>
  );
};

export default OfflineHandler;
