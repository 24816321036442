import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import homeIcon from '/assets/images/homeIcon.png';
import cameraScannerIcon from '/assets/images/cameraScannerIcon.png';
import notificationIcon from '/assets/images/notificationIcon.png';
import cameraScannerIconPrimary from '/assets/images/cameraScannerIconPrimary.png';
import homeUnreportedIcon from '/assets/images/homeUnreportedIcon.png';
import { useSelector } from 'react-redux';

const Footer = () => {
  const [isUnreported, setIsUnreported] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { data: notification } = useSelector((state) => state.notificationInfo);

  useEffect(() => {
    localStorage?.removeItem('selectedUnreported');
    setIsUnreported(location.pathname === '/un-reported-expenses');
  }, [location.pathname]);

  return (
    <footer className="fixed bottom-0 w-full bg-white border-t-2 py-2 shadow-top lg:hidden">
      <div className="flex justify-around items-center h-16 ">
        <button
          className="footer-button"
          onClick={() => navigate('/home')}
          aria-label="Home"
        >
          <img
            src={isUnreported ? homeUnreportedIcon : homeIcon}
            alt="Home"
            width={25}
            height={25}
          />
        </button>
        <Link to="/camera">
          <button className="footer-button" aria-label="Camera Scanner">
            <img
              src={isUnreported ? cameraScannerIconPrimary : cameraScannerIcon}
              alt="camere-scanner"
              height={'50px'}
              width={'50px'}
            />
          </button>
        </Link>
        <Link to="/notification">
          <button className="footer-button" aria-label="Notification">
            <div className="relative">
              <img
                src={notificationIcon}
                alt="notification"
                height={'32px'}
                width={'32px'}
              />
              <span className="absolute top-0 right-0 bg-primary text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
                {notification?.notificationMessage?.length}
              </span>
            </div>
          </button>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
