import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import MyExpenseList from './MyExpenseList';
import Loader from '../../components/modal/Loader';
import FileAttachedModal from '../../components/modal/FileAttachedModal';
import Button from '../../components/button/Button';
import ModifyRoutes from './ModifyRoutes';
import PopupModal from '../../components/modal/PopupModal';
import {
  fetchDayWiseExpense,
  fetchAttachmentDayWise,
  deletedAttachmentDayWise,
  fetchDayWiseTotalExpense,
  getExpenseDayWiseAll,
  expenseUpdateDateWiseSubmit,
  // getAttachmentDaywise
} from '../../redux/slice/myExpenseDayWiseSlice';

// images
import attachmentIcon from '/assets/images/attachmentIcon.png';
import removeIcon from '/assets/images/removeIcon.png';
import leftArrowIcon from '/assets/images/leftArrowIcon.png';

import { userInfo } from '../../utils/Constant';
const { employeeId } = userInfo;
const MyExpenseDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isLoading,
    apiError,
    errorMessage,
    dayWiseExpense,
    attachmentDayWise,
    dayWiseTotalExpense,
  } = useSelector(getExpenseDayWiseAll);

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);

  const toggleDrawer = () => setDrawerOpen(!isDrawerOpen);

  useEffect(() => {
    dispatch(fetchDayWiseTotalExpense());
    const selectedExpenseData = localStorage.getItem('SelectedExpenseData');
    if (selectedExpenseData) {
      const { activityDate } = JSON.parse(selectedExpenseData);
      dispatch(fetchDayWiseExpense(activityDate));
    }
  }, [dispatch, isDrawerOpen]);

  useEffect(() => {
    const fetchAttachments = async () => {
      const response = await dispatch(fetchAttachmentDayWise());
      console.log('Attachment response:', response);
    };

    fetchAttachments();
  }, [dispatch]);

  const handleNavigate = () => {
    navigate('/my-expense');
    localStorage.removeItem('SelectedExpenseData');
  };

  const handleDeleteAttachment = async (item) => {
    await dispatch(deletedAttachmentDayWise(item));
    dispatch(fetchAttachmentDayWise());
    dispatch(fetchDayWiseTotalExpense());
  };

  const handlerNavigate = (navigateTo) => {
    navigate(navigateTo);
  };

  const handlerSaveUpdate = () => {
    let { total } = dayWiseTotalExpense;
    const selectedExpenseData = localStorage.getItem('SelectedExpenseData');
    const { activityDate, expenseId } = JSON.parse(selectedExpenseData);
    const data = {
      employeeId,
      total,
      activityDate,
      expenseId,
    };
    dispatch(expenseUpdateDateWiseSubmit(data));
    setSuccessModal(true);
    setTimeout(() => {
      navigate('/my-expense');
      localStorage.removeItem('SelectedExpenseData');
    }, 1000);
  };

  const closeModal = () => {
    setSuccessModal(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (apiError) {
    return <div className="text-red-500">{errorMessage}</div>;
  }

  const dividerClass = 'divider-y h-[0.5px] w-full bg-line-color';

  return (
    <div className="w-full h-screen flex lg:pt-14 lg:pl-2 lg:pr-8">
      <div className="w-full bg-white relative flex flex-col">
        <header className="w-full lg:pl-2 smAndMd:px-2 py-2 flex items-center  smAndMd:pt-6  smAndMd:pb-6">
          <div className="flex-1 flex items-center">
            <button onClick={handleNavigate} className="lg:hidden mr-4">
              <img
                src={leftArrowIcon}
                alt="leftIcon"
                height={'10px'}
                width={'10px'}
              />
            </button>
            <p className="text-baselg font-semibold  text-primary">
              Expense Details
            </p>
          </div>
          <button
            onClick={toggleDrawer}
            className="font-semibold text-baseSm lg:text-baseMd text-primary smAndMd:pt-6"
          >
            Modify
          </button>
          <ModifyRoutes isOpen={isDrawerOpen} onClose={toggleDrawer} />
        </header>
        <div className='w-[99%] ml-2 border-b border-line-color'></div>




        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-8/12  lg:border-line-color lg:pt-2">
            {dayWiseExpense &&
              dayWiseExpense.length > 0 &&
              dayWiseExpense.map((item, index) => (
                <div key={index}>
                  <div className="flex justify-between items-center">
                    <MyExpenseList
                      id={index}
                      item={item}
                      className="w-full lg:w-7/12"
                    />
                  </div>
                  <div className="divider-y h-[2px] lg:h-0 bg-line-color"></div>
                </div>
              ))}
          </div>

          <div className="lg:w-4/12  lg:mt-6 border-l-[0.5px] border-line-color  lg:h-[65vh]">
            <div
              className="flex justify-between pl-4 pt-1"
              style={{ backgroundColor: '#F7F0FF', height: '54px' }}
            >
              <div className="pt-1">
                <p className="font-bold text-base lg:text-baseMd">
                  Total Expense Details
                </p>
                <p className="text-baseXSm">Attach other allowance here</p>
              </div>
              <button
                onClick={() => setShowModal(!showModal)}
                className="px-4 py-2 lg:py-0 text-white relative"
                style={{ borderColor: '#A04D95' }}
              >
                <FileAttachedModal
                  onClose={closeModal}
                  showModal={showModal}
                  onClick={() => setShowModal(!showModal)}
                />
                <img
                  src={attachmentIcon}
                  alt="attachmentIcon"
                  height={'20px'}
                  width={'20px'}
                />
              </button>
            </div>

            <div className="flex justify-between my-3 pl-4 pr-4 lg:w-full">
              <p className="text-baseSm text-[#7A7A7A]">Total Fare</p>
              <p className="text-baseSm text-[#7A7A7A] font-medium">
                ₹ {dayWiseTotalExpense?.fare || 0}
              </p>
            </div>

            {/* Attachments List */}
            {attachmentDayWise &&
              attachmentDayWise.length > 0 &&
              attachmentDayWise.map((item) => (
                <div
                  key={item.expenseAttachmentId}
                  className="flex justify-between my-3 pl-4 pr-4 lg:w-full"
                >
                  <p className="text-baseSm text-[#7A7A7A]">
                    {item.expenseType}
                  </p>
                  <p className="text-baseSm text-[#7A7A7A] font-medium">
                    ₹ {item.fare}
                  </p>
                </div>
              ))}

            {/* Total Section */}
            <div className="divider-y h-[0.5px] w-[92%] bg-line-color ml-4"></div>
            <div className="flex justify-between my-3 pl-4 pr-4 lg:w-full">
              <p className="text-baseMd font-semibold">Total Expense</p>
              <p className="text-baseMd text-primary-black font-medium tracking-[1px]">
                ₹ {dayWiseTotalExpense?.total || 0}
              </p>
            </div>

          <div className="divider-y h-[0.5px] w-full bg-line-color"></div>

          <div className="p-3 pb-32 lg:w-full">
            <p className="text-baseMd font-semibold text-[#514D4C]">
              Attachments
            </p>
            {attachmentDayWise &&
              attachmentDayWise?.length > 0 &&
              attachmentDayWise?.map((item) => (
                <div
                  key={item.expenseAttachmentId}
                  className="flex justify-between p-2 border border-line-color rounded-lg mt-3"
                >
                  <div className="flex items-center">
                    <img
                      src={`data:image/png;base64,${item.imageBase64}`}
                      alt="attachment"
                      className="w-12 h-12 rounded-lg object-cover"
                    />
                    <div className="ml-2">
                      <p>{item.expenseType}</p>
                      <p>{item.fare}</p>
                    </div>
                  </div>
                  <button onClick={() => handleDeleteAttachment(item)}>
                    <div className="rounded-full mr-3">
                      <img
                        src={removeIcon}
                        alt="removeIcon"
                        height={'25px'}
                        width={'25px'}
                      />
                    </div>
                  </button>
                </div>
              ))}
          </div>
        </div>
        </div>
        <div className=" fixed  divider-y h-[1px] w-[76.5%] ml-2  bg-line-color bottom-20"></div>
        <div className="flex fixed bottom-3 right-8 gap-3 justify-around items-center">
          <Button
            label="Back"
            btTextColor="text-primary"
            btBgColor="white"
            btHeight="h-10"
            btWidth="w-36"
            onClick={() => handlerNavigate('/my-expense')}
          />
          <Button
            label="Save"
            onClick={handlerSaveUpdate}
            btBgColor="bg-primary"
            btHeight="h-10"
            btWidth="w-36"
            btTextColor="text-white"
          />
        </div>

        {/* Mobile Bottom Buttons */}
        {!isDrawerOpen && (
          <div className="lg:hidden fixed bottom-0 right-0 bg-white border-t-[1px] shadow-top1 h-[7rem] w-full flex justify-around items-center gap-3 p-4">
            <Button
              label="Back"
              btTextColor="text-primary"
              btBgColor="white"
              onClick={() => handlerNavigate('/my-expense')}
            />
            <Button
              label="Save"
              onClick={handlerSaveUpdate}
              btBgColor="bg-primary"
              btTextColor="text-white"
            />
          </div>
        )}

        {/* Success Modal */}
        {showSuccessModal && (
          <PopupModal
            time={2000}
            isOpen={showSuccessModal}
            message={'Expense Updated Successfully'}
            type={'success'}
            onClose={closeModal}
          />
        )}
      </div>
    </div>
  );
};

export default MyExpenseDetails;